
import { HttpClient } from '@angular/common/http';
import { Injectable, } from '@angular/core';
import { Observable } from 'rxjs';
import { ReportResult, ReportResultList } from 'src/app/core/data/report';
import { environment } from '../../../environments/environment';
import { ReportModel, FileUrl, ReportList } from '../data/report';

@Injectable({
    providedIn: 'root'
})
export class SetService {

    constructor(private http: HttpClient) { }

    getReport(reportId: string, playerId: string): Observable<ReportModel> {
        return this.http.get<ReportModel>(environment.apiUrl + '/v1/players/' + playerId + '/reports/' + reportId);
    }

    getReportsEvolutions(playerId: string): Observable<any> {
        return this.http.get<any>(environment.apiUrl + '/v1/players/' + playerId + '/reports-evolution');
    }


    getAllReports(playerId: string,): Observable<any> {
        return this.getReports(playerId, "", 'createdOn', 0, 0, 5000)
    }

    getReports(playerId: string, globalFilter: string, sortField: string, sortOrder: number, first: number, rows: number): Observable<ReportList[]> {
        return this.http.get<any>(environment.apiUrl + '/v1/players/' + playerId + '/reports'
            + '?globalFilter=' + (globalFilter ? globalFilter : '')
            + '&sortField=' + (sortField ? sortField : 'dateCreated')
            + '&sortOrder=' + (sortOrder ? sortOrder : 0)
            + '&pageIndex=' + ((first / rows) + 1)
            + '&pageSize=' + (rows ? rows : 10)
        );
    }

    generateReportPdf(playerId: string, reportId: string, lang: string): Observable<FileUrl> {
        return this.http.get<FileUrl>(environment.apiUrl + '/v1/players/' + playerId + '/reports/' + reportId + '/pdf' + '?langcode=' + lang);
    }


    //Resultados
    getReportResultsForCapacity(playerId: string, reportId: string, capacityEnum: number): Observable<ReportResultList> {
        return this.http.get<ReportResultList>(environment.apiUrl + '/v1/players/' + playerId + '/reports/' + reportId + '/results?capacity=' + capacityEnum);
    }

    getReportResultById(playerId: string, reportId: string, reportResultId: string, capacityEnum: number): Observable<ReportResult> {
        return this.http.get<ReportResult>(environment.apiUrl + '/v1/players/' + playerId + '/reports/' + reportId + '/results/' + reportResultId + '?capacity=' + capacityEnum);
    }


    markReportResultAsRead(playerId: string, reportId: string, reportResultId: string,): Observable<any> {
        return this.http.put<string>(environment.apiUrl + '/v1/players/' + playerId + '/reports/' + reportId + '/results/' + reportResultId, {});
    }
}
