// Devuelve el nombre de la imagen en el svg (icono barra con o sin  color)

import { CapacityType } from "../const/capacity";
import { ReasonNoAttendance, EventDataFilterType } from "../const/coach";
import { format, utcToZonedTime } from 'date-fns-tz';
import * as CryptoJS from 'crypto-js';
import { environment } from "src/environments/environment";
import { FormControl, FormGroup } from "@angular/forms";
import { v4 as uuidv4 } from 'uuid';
export function changeDate(s: string) {
    let base = s;

    const indexHour = s.indexOf(':');
    if (indexHour == -1) {
        base += 'T00:00:00';
    }
    const indexZ = s.indexOf('+');
    if (indexZ == -1) {
        base = base + '+00:00';
    }
    return base;
}
export function DateToyyyyMMddTHHmmssZ(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const seconds = date.getSeconds().toString().padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}Z`;
}


// se usa para devolver los iconos de la lista de capacidades
export function getImageValue(value: number, white: boolean) {
    let image = '';
    let image_red = 'circle-note-e' + (white ? '-white' : '');
    let image_orange = 'circle-note-d' + (white ? '-white' : '');
    let image_yellow = 'circle-note-c' + (white ? '-white' : '');
    let image_green = 'circle-note-b' + (white ? '-white' : '');
    let image_blue = 'circle-note-a' + (white ? '-white' : '');


    if (value < 1.5) {
        image = image_red;
    } else if (value >= 1.5 && value < 2.5) {
        image = image_orange;
    } else if (value >= 2.5 && value < 3.5) {
        image = image_yellow;
    } else if (value >= 3.5 && value < 4.5) {
        image = image_green;
    } else if (value >= 4.5) {
        image = image_blue;
    }
    return image;
}

export function getLetterByValue(value: number) {

    var letter = '';
    if (value < 1.5) {
        letter = 'E';
    } else if (value >= 1.5 && value < 2.5) {
        letter = 'D';
    } else if (value >= 2.5 && value < 3.5) {
        letter = 'C';
    } else if (value >= 3.5 && value < 4.5) {
        letter = 'B';
    } else if (value >= 4.5) {
        letter = 'A';
    }
    return letter;
}

// devuelve la clase para asociar back color de cada puntuacion
export function getClassValue(value: number) {
    let color: string;
    if (value < 1.5) {
        color = 'alignCenter colorE';
    } else if (value >= 1.5 && value < 2.5) {
        color = 'alignCenter colorD';
    } else if (value >= 2.5 && value < 3.5) {
        color = 'alignCenter colorC';
    } else if (value >= 3.5 && value < 4.5) {
        color = 'alignCenter colorB';
    } else if (value >= 4.5) {
        color = 'alignCenter colorA';
    }
    return color;
}


// devuelve el codigo de color rgb de cada puntuacion
export function getColorValue(i: number) {
    let color: string;
    let red = 'rgba(203,84,86,1)';
    let orange = 'rgba(222,123,125,1)';
    let yellow = 'rgba(247,226,143,1)';
    let green = 'rgba(101,213,173,1)';
    let blue = 'rgba(56,125,200,1)';


    if (i < 1.5) {
        color = red;
    } else if (i >= 1.5 && i < 2.5) {
        color = orange;
    } else if (i >= 2.5 && i < 3.5) {
        color = yellow;
    } else if (i >= 3.5 && i < 4.5) {
        color = green;
    } else if (i >= 4.5) {
        color = blue;
    }
    return color;
}
export function getCapacityCodeTitle(capacity: number) {
    if (capacity == CapacityType.COORDINATIVE) return 'PROFILE.COORD_TITLE';
    if (capacity == CapacityType.COGNITIVE) return 'PROFILE.COG_TITLE';
    if (capacity == CapacityType.MENTHAL) return 'PROFILE.MENTAL_TITLE';
    if (capacity == CapacityType.SOCIO_AFFECTIVE) return 'PROFILE.SOCIO_TITLE';
    if (capacity == CapacityType.EMOTIVE_VOLUTIVE) return 'PROFILE.EMO_TITLE';
    if (capacity == CapacityType.EXPRESSIVE_CREATIVE) return 'PROFILE.EXP_TITLE';
    return "";

}


// **********Position********************
export function getCapacityCodeText(capacityScoreText: string) {

    return 'GENERAL.' + capacityScoreText;;

}


export function getInitials(name: string, firstname: string, lastname: string) {
    let initials = '';
    if (name) {
        initials += name.substring(0, 1);
    } else {
        initials += 'A';
    }

    if (firstname) {
        initials += firstname.substring(0, 1);
    } else {
        if (lastname) {
            initials += lastname.substring(0, 1);
        } else {
            initials += 'A';
        }
    }
    return initials;
}

//********Entrenamientos y Partidos */
export function getResonNoAttendanceCode(resonId: number) {
    var allReasons = ReasonNoAttendance;
    var code = "COACH." + allReasons.get(resonId);
    return code;
}

export function getDataFilter(selectedFilter: number) {
    var currentDate = new Date();
    var result = { fromDate: new Date(), toDate: new Date() }

    switch (selectedFilter) {
        case EventDataFilterType.TODAY:
            result.fromDate = currentDate;
            result.toDate = currentDate;
            break;
        case EventDataFilterType.TOMORROW:
            result.fromDate.setDate(currentDate.getDate() + 1);
            result.toDate.setDate(currentDate.getDate() + 1);
            break;
        case EventDataFilterType.YESTERDAY:
            result.fromDate.setDate(currentDate.getDate() - 1);
            result.toDate.setDate(currentDate.getDate() - 1);
            break;
        case EventDataFilterType.NEXT_WEEK:
            result.fromDate = currentDate;
            result.toDate.setDate(result.fromDate.getDate() + 7);
            break;
        case EventDataFilterType.LAST_WEEK:
            result.fromDate.setDate(currentDate.getDate() - 7);
            result.toDate = currentDate;
            break;
        case EventDataFilterType.NEXT_MONTH:
            result.fromDate = currentDate;
            result.toDate.setDate(result.fromDate.getDate() + 30);
            break;
        case EventDataFilterType.LAST_MONTH:
            result.fromDate.setDate(currentDate.getDate() - 30);
            result.toDate = currentDate;
            break;
        default:
            result.fromDate = currentDate;
            result.toDate.setDate(currentDate.getDate() + 7);
            break;
    }
    return result;
}



// **********Errors********************
export function GetErrorCodes(error, defaultErrorCode = null) {
    var errorCodes = [];
    if (error?.error?.errors) {
        error.error.errors.forEach((error) => {
            errorCodes.push("ERRORS." + error);
        });

    }
    else if (defaultErrorCode)
        errorCodes.push(defaultErrorCode);
    else {
        errorCodes.push('ERRORS.GENERAL_ERROR');
    }
    return errorCodes;
}

export function GetErrorMessage(translations) {

    if (typeof translations === 'string') {
        return translations;
    }
    else {

        let keyTrErrors = Object.keys(translations);
        let errorMesages = [];
        for (var keyError of keyTrErrors) {
            errorMesages.push(translations[keyError]);
        }
        var errorMessage = errorMesages ? errorMesages.map(x => x).join(",") : "";
        return errorMessage
    }

}

/********** DATES********** */
// Método que añade el UTC a una fecha y hora con un locale, y devuelve esta fecha formateada.
export function ConfigDateTime(localDate: string) {
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const zonedTime = utcToZonedTime(localDate, userTimeZone);
    var formattedDate = format(zonedTime, 'yyyy-MM-dd\'T\'HH:mm:ssXXX', { timeZone: userTimeZone });

    return formattedDate;
}

//Obtiene la hora local
export function getLocalDate(date) {

    const now = new Date();
    var tempDate = new Date(date);
    return new Date(tempDate.getTime() - (now.getTimezoneOffset() * 60000));
}

//Obtiene la fecha actual en mm/dd/yyyy HH:mm:ss
export function getDateNowUTCToString() {

    const date = new Date();

    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Month is 0-based
    const day = String(date.getUTCDate()).padStart(2, '0');

    const hour = String(date.getUTCHours()).padStart(2, '0');
    const minute = String(date.getUTCMinutes()).padStart(2, '0');
    const second = String(date.getUTCSeconds()).padStart(2, '0');

    return (month + "/" + day + "/" + year + " " + hour + ":" + minute + ":" + second);

}


/************** CRYPTO JS************************ */
export function decryptData(text, key) {

    var kyeNormalized = key.length > 32 ? key.substring(0, 32) : key;
    var keyUtf8 = CryptoJS.enc.Utf8.parse(kyeNormalized);
    var ivUtf8 = CryptoJS.enc.Utf8.parse(environment.Encrypt.IV);
    var decrypted = CryptoJS.AES.decrypt(text, keyUtf8, {
        keySize: 128 / 8,
        iv: ivUtf8,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
}

/*************** */
export function toCamel(o) {
    var newO, origKey, newKey, value
    if (o instanceof Array) {
        return o.map(function (value) {
            if (typeof value === "object") {
                value = toCamel(value)
            }
            return value
        })
    } else {
        newO = {}
        for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                newKey = (origKey.charAt(0).toLowerCase() + origKey.slice(1) || origKey).toString()
                value = o[origKey]
                if (value instanceof Array || (value !== null && value.constructor === Object)) {
                    value = toCamel(value)
                }
                newO[newKey] = value
            }
        }
    }
    return newO
}
export function toUpper(o) {
    var newO, origKey, newKey, value
    if (o instanceof Array) {
        return o.map(function (value) {
            if (typeof value === "object") {
                value = toUpper(value)
            }
            return value
        })
    } else {
        newO = {}
        for (origKey in o) {
            if (o.hasOwnProperty(origKey)) {
                newKey = (origKey.charAt(0).toUpperCase() + origKey.slice(1) || origKey).toString()
                value = o[origKey]
                if (value instanceof Array || (value !== null && value?.constructor === Object)) {
                    value = toUpper(value)
                }
                newO[newKey] = value
            }
        }
    }
    return newO
}

export function clearNullProperties(objeto: { [key: string]: any }): void {
    for (const clave in objeto) {
        if (objeto.hasOwnProperty(clave) && objeto[clave] === null) {
            delete objeto[clave];
        }
    }
}


export function generateUniqueId(): string {
    // Generar un identificador único usando uuid
    return uuidv4();
}

//VALIDACIÓN
export function validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);
        if (control instanceof FormControl) {
            if (control.status === "INVALID") {
                control.markAsDirty({ onlySelf: true });
            }
        } else if (control instanceof FormGroup) {
            this.validateAllFormFields(control);
        }
    });
}