
import { PlayerRegister } from '../data/register';

//REGISTER
export function setPlayerRegisterStorage(playerRegister: PlayerRegister
    , step?: number
    , nextStep?: number): PlayerRegister {
    if (playerRegister?.userName === '') {
        playerRegister.userName = null;
    }
    if (step) {
        switch (step) {
            case 1: //select profile
                playerRegister.country = null;
                playerRegister.place = null;
                playerRegister.club = null;
                playerRegister.team = null;
                playerRegister.category = null;
                playerRegister.gender = null;
                clearDataStepSeletPlayer(playerRegister);
                break;
            case 2://select country
                playerRegister.place = null;
                playerRegister.club = null;
                playerRegister.team = null;
                playerRegister.category = null;
                playerRegister.gender = null;
                clearDataStepSeletPlayer(playerRegister);
                break;
            case 3://select locality
                playerRegister.club = null;
                playerRegister.team = null;
                playerRegister.category = null;
                playerRegister.gender = null;
                clearDataStepSeletPlayer(playerRegister);
                break;
            case 4: //select club
                playerRegister.team = null;
                playerRegister.category = null;
                playerRegister.gender = null;
                clearDataStepSeletPlayer(playerRegister);
                break;
            case 5://new club
                playerRegister.team = null;
                playerRegister.category = null;
                playerRegister.gender = null;
                clearDataStepSeletPlayer(playerRegister);
                break;
            case 6://select category
                playerRegister.gender = null;
                playerRegister.team = null;
                clearDataStepSeletPlayer(playerRegister);
                break;
            case 7://select gender
                clearDataStepSeletPlayer(playerRegister);
                playerRegister.team = null;
                break;
            case 8://select team
                clearDataStepSeletPlayer(playerRegister);
                break;
            case 9://new team
                clearDataStepSeletPlayer(playerRegister);
                break;
            case 10://select player
                if (!playerRegister.id) {
                    clearDataStepSeletPlayer(playerRegister);
                }
                break;
            case 11://select position
                playerRegister.name = null;
                playerRegister.firstname = null;
                playerRegister.lastname = null;
                playerRegister.nickName = null;
                break;
            case 12://new player
                break;
            case 13://summary
                break;
            default:
                break;

        }

    }
    if (nextStep)
        updateStepPlayerRegisterStorage(playerRegister, nextStep)
    localStorage.setItem('playerRegister', JSON.stringify(playerRegister));
    return playerRegister;
}

export function getPlayerRegisterStorage(): PlayerRegister {
    let playerRegister: PlayerRegister;
    let playerRegisterStorage = JSON.parse(localStorage.getItem('playerRegister')!);
    if (playerRegisterStorage) {
        if (playerRegisterStorage.userName === '') {
            playerRegisterStorage.userName = null;
        }
        playerRegister = new PlayerRegister();
        playerRegister = Object.assign(playerRegister, playerRegisterStorage);
    }

    return playerRegister;
}

export function clearPlayerRegisterStorage() {
    localStorage.removeItem('playerRegister');
}
function updateStepPlayerRegisterStorage(playerRegister: PlayerRegister, step: number) {
    if (!playerRegister) {
        playerRegister = new PlayerRegister();
        playerRegister.steps = [];
    }
    else {
        if (!playerRegister.steps) {
            playerRegister.steps = [step];
        }
        else {
            playerRegister.steps = playerRegister.steps.filter(p => p < step);
            playerRegister.steps.push(step);
        }
    }
}

function clearDataStepSeletPlayer(playerRegister: PlayerRegister) {
    playerRegister.id = null;
    playerRegister.name = null;
    playerRegister.firstname = null;
    playerRegister.lastname = null;
    playerRegister.nickName = null;
    playerRegister.position = null;
}


//PLAYER
export function getLastPlayerStorage() {
    return localStorage.getItem('lastPlayer');
}

export function setLastPlayerStorage(playerId: string) {
    return localStorage.setItem('lastPlayer', playerId);
}
export function setHasCoahPlayerStorage(hasCoach: boolean) {
    return localStorage.setItem('coach', hasCoach ? "1" : "0");
}
export function getHasCoahPlayerStorage(): boolean {
    return localStorage.getItem("coach") == "1";
}

export function setPlayerLastAccessStorage(playerId: string) {
    const currentDate = new Date();
    const formatDate = `${currentDate.getMonth() + 1}-${currentDate.getDate()}-${currentDate.getFullYear()}`;
    return localStorage.setItem(playerId, formatDate);
}
export function getPlayerLastAccessStorage(playerId: string) {
    return localStorage.getItem(playerId);
}


//USER
export function getUserStorage() {
    const user = JSON.parse(localStorage.getItem('user')!);
    return user;
}
export function getUserEmailStorage() {
    const user = getUserStorage();
    return user?.email;
}

export function setUserStorage(user) {
    localStorage.setItem('user', JSON.stringify(user));
}

//GAMES
export function setUrlGameStorage(urlGame) {
    localStorage.setItem('urlGame', urlGame);
}
export function getUrlGameStorage() {
    return localStorage.getItem("urlGame");;
}



export function clearStorage() {
    localStorage.removeItem('user');
    localStorage.removeItem('coach');
    localStorage.removeItem('lastPlayer');
    localStorage.removeItem("urlGame");
    localStorage.clear();
    clearPlayerRegisterStorage();
}