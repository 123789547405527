export class Height {
    id: string;
    centimeters: number;
    created: Date;
    playerName: string;
}

export class Weight {
    id: string;
    kilograms: number;
    created: Date;
}