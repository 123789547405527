import { Component, Input } from '@angular/core';
import { AlertController, ModalController, PopoverController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { NotificationModel } from 'src/app/core/data/user';
import { NotificationType } from 'src/app/core/const/notification';
import { BiometricHeightModalComponent } from 'src/app/core/components/biometric-modal/height/biometric-height-modal.component';
import { BiometricWeightModalComponent } from 'src/app/core/components/biometric-modal/weight/biometric-weight-modal.component';
import { take } from 'rxjs';


@Component({
  selector: 'header-popover-not',
  templateUrl: 'notifications.popover.component.html',
  styleUrls: ['notifications.popover.component.scss']
})
export class NotificationsPopoverComponent {
  modalHeight: HTMLIonModalElement;
  modalWeight: HTMLIonModalElement;
  @Input() notifications: NotificationModel[];

  constructor(private popoverController: PopoverController,
    private alertController: AlertController,
    private modalCtrl: ModalController,
    private translateService: TranslateService) {
  }

  async openNotification(notification) {
    if (notification.playerId && notification.type == NotificationType.HEIGHT_NOT_CONFIRMATED) {
      //pasamos el playerId al modal de la altura y este nos devuelve el (heightCM) de la modal de la altura
      this.modalHeight = await this.modalCtrl.create({
        component: BiometricHeightModalComponent,
        cssClass: '',
        componentProps: {
          playerId: notification.playerId,
          playerName: notification.playerName
        }
      });
      return await this.modalHeight.present().then(x => {
        this.popoverController.dismiss();
      });
    }

    else if (notification.playerId && notification.type == NotificationType.WEIGHT_NOT_CONFIRMATED) {
      //pasamos el playerId al modal de la altura y este nos devuelve el (heightCM) de la modal de la altura
      this.modalWeight = await this.modalCtrl.create({
        component: BiometricWeightModalComponent,
        cssClass: '',
        componentProps: {
          playerId: notification.playerId,
          playerName: notification.playerName
        }
      });
      return await this.modalWeight.present().then(x => {
        this.popoverController.dismiss();
      });

    }

    else {
      this.translateService.get(['NOTIFICATIONS.' + notification.code, 'NOTIFICATIONS.' + notification.code + '_TEXT'])
        .pipe(take(1)).subscribe(async t => {
          const alert = await this.alertController.create({
            cssClass: 'my-select-class',

            header: t['NOTIFICATIONS.' + notification.code],
            message: t['NOTIFICATIONS.' + notification.code + '_TEXT'],
            buttons: [
              {
                text: 'OK',
                cssClass: 'alert-button-cancel',
                handler: async () => { }

              }
            ]

          });
          await alert.present().then(x => {
            this.popoverController.dismiss();
          });
        });
    }
  }

}
