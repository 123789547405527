
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PlayerDetails, PlayerNote, PlayerBasic, EditPlayerDetails, CompletePlayerDetails, EditPlayerTeamDetails, PlayerEvolution } from '../data/player';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { Quiz } from 'src/app/core/data/quiz';
import { GuidResponse } from '../data/form';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {

    constructor(private http: HttpClient) { }

    /*********  METODOS DEL SERVICIO ************/


    existsUserForPlayer(playerId: string): Observable<any> {
        return this.http.head<any>(environment.apiUrl + '/v1/players/' + playerId + "/users");
    }

    getPlayerDetails(playerId: string): Observable<PlayerDetails> {
        return this.http.get<PlayerDetails>(environment.apiUrl + '/v1/players/' + playerId + '/detail');
    }

    getPlayerEvolution(playerId: string): Observable<PlayerEvolution> {
        return this.http.get<PlayerEvolution>(environment.apiUrl + '/v1/players/' + playerId + '/evolution');
    }


    getPlayerImage(playerId: string): Observable<GuidResponse> {
        return this.http.get<GuidResponse>(environment.apiUrl + '/v1/players/' + playerId + '/image');
    }

    deletePlayerImage(playerId: string): Observable<boolean> {
        var updateObject = [];
        var optionPercentage = { "path": "UrlImage", "op": "replace", "value": "" };
        updateObject.push(optionPercentage);
        return this.http.patch<boolean>(environment.apiUrl + '/v1/players/' + playerId, updateObject);
    }

    getPlayerBasicById(playerId: string): Observable<PlayerBasic> {
        return this.http.get<PlayerBasic>(environment.apiUrl + '/v1/players/' + playerId + '/basic');
    }

    createPlayer(player: CompletePlayerDetails): Observable<PlayerDetails> {
        return this.http.post<PlayerDetails>(environment.apiUrl + '/v1/players', player);
    }

    updatePlayer(player: PlayerDetails, laterality: number): Observable<EditPlayerDetails> {

        const playerEdit: EditPlayerDetails = new EditPlayerDetails(player, laterality);
        return this.http.put<EditPlayerDetails>(environment.apiUrl + '/v1/players/' + player.id, playerEdit);
    }

    updatePlayerTeam(id: string, player: EditPlayerTeamDetails): Observable<EditPlayerTeamDetails> {
        return this.http.put<EditPlayerTeamDetails>(environment.apiUrl + '/v1/players/' + id + '/teams', player);
    }

    uploadPlayerImage(id: string, form): Observable<PlayerDetails> {
        return this.http.post<PlayerDetails>(environment.apiUrl + '/v1/players/' + id + '/image', form);
    }

    sendSignalRConnection(connectionId: string, playerId: string): Observable<any> {
        const params = {
            connectionId,
            playerId
        };
        return this.http.post<boolean>(environment.apiUrl + '/v1/players/' + playerId + '/connections', params);
    }

    // MATES
    getMatesByTeam(playerId: string, teamId: string): Observable<PlayerNote[]> {
        return this.http.get<PlayerNote[]>(environment.apiUrl + '/v1/players/' + playerId + '/teams/' + teamId + "/mates");
    }


    //Entrenamientos
    getNextTrainingsByPlayer(playerId: string, globalFilter: string, sortField: string, sortOrder: number, first: number, rows: number) {

        var currentDate = new Date();
        return this.getTrainingsByPlayer(playerId, currentDate, null, globalFilter, sortField, sortOrder, first, rows);
    }

    getTrainingsByPlayer(playerId: string, fromDate: Date, toDate: Date, globalFilter: string, sortField: string, sortOrder: number, first: number, rows: number): Observable<any> {
        return this.http.get<any>(environment.apiUrl + '/v1/players/' + playerId + '/trainings' + '?'
            + (fromDate ? 'fromDate=' + fromDate.toISOString() : '&fromDate=')
            + (toDate ? '&toDate=' + toDate.toISOString() : '&toDate=')
            + '&globalFilter=' + (globalFilter ? globalFilter : '')
            + '&sortField=' + (sortField ? sortField : 'date')
            + '&sortOrder=' + (sortOrder ? sortOrder : 0)
            + '&pageIndex=' + (first / rows + 1)
            + '&pageSize=' + (rows ? rows : 10));
    }

    //Partidos
    getNextGamesByPlayer(playerId: string, globalFilter: string, sortField: string, sortOrder: number, first: number, rows: number) {

        var currentDate = new Date();
        return this.getGamesByPlayer(playerId, currentDate, null, globalFilter, sortField, sortOrder, first, rows);
    }

    getGamesByPlayer(playerId: string, fromDate: Date, toDate: Date, globalFilter: string, sortField: string, sortOrder: number, first: number, rows: number): Observable<any> {
        return this.http.get<any>(environment.apiUrl + '/v1/players/' + playerId + '/games' + '?'
            + (fromDate ? 'fromDate=' + fromDate.toISOString() : '&fromDate=')
            + (toDate ? '&toDate=' + toDate.toISOString() : '&toDate=')
            + '&globalFilter=' + (globalFilter ? globalFilter : '')
            + '&sortField=' + (sortField ? sortField : 'date')
            + '&sortOrder=' + (sortOrder ? sortOrder : 0)
            + '&pageIndex=' + (first / rows + 1)
            + '&pageSize=' + (rows ? rows : 10));
    }

    //Quiz
    saveQuiz(quiz: Quiz): Observable<boolean> {
        return this.http.put<boolean>(environment.apiUrl + '/v1/players/' + quiz.playerId + '/quizzes/' + quiz.key, quiz);
    }






}
