import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-autologin',
    templateUrl: './autologin.component.html',
    styleUrls: ['./autologin.component.scss']
})
export class AutologinComponent implements OnInit {

    error: boolean;
    spinnerLoading: boolean;

    token: string;
    email: string;
    prescriptorCode: string;

    constructor(
        private route: ActivatedRoute,
    ) {
        this.route.queryParams.subscribe(params => this.loadQueryParams(params));
    }


    loadQueryParams(params) {
        this.token = params.token;
        this.email = params.email;
        this.prescriptorCode = params.code;

        if (this.prescriptorCode) {
            localStorage.setItem('prescriptorCode', this.prescriptorCode);
        }
    }

    async ngOnInit() {
        this.spinnerLoading = true;
        //login with token
        //this.authService.startAuthenticationToToken(this.token, this.email);

    }
}
