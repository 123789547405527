<ion-header translucent>
    <ion-toolbar class="alignCenter">
        <ion-label>{{'PROFILE.WEIGHT_REGISTER' | translate | uppercase}}
            <span *ngIf="playerNameInfo">{{playerNameInfo}}</span></ion-label>
        <ion-buttons slot="end">
            <ion-icon name="close-outline" (click)="closeModal()"></ion-icon>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-grid>
        <ion-row class="marginTop20">
            <ion-col size-xs="7" size-sm="6" size-md="6">
                <ion-input class="biometric-param" #inputWeight type="number" min="0"
                    onkeypress="return event.charCode >= 46 || event.charCode ==44" [(ngModel)]="weight"
                    placeholder="{{'PROFILE.WEIGHT_PLAYER_PLACEHOLDER' | translate}} kg">
                </ion-input>
            </ion-col>
            <ion-col size-xs="5" size-sm="6" size-md="6">
                <ion-button [disabled]="weight == null" (click)="addWeight()">
                    {{'PROFILE.ADD_WEIGHT' | translate}}
                </ion-button>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-list>
                    <ion-item *ngFor=" let weight of weights; let i = index;">
                        <ion-label> {{'PROFILE.CREATED_ON' | translate}} : {{weight.created | date: 'dd/MM/yyyy' }} -
                            {{'PROFILE.WEIGHT_PLAYER' | translate}} : {{weight.kilograms|number}} kg
                        </ion-label>
                        <ion-button (click)="deleteWeight(weight)">
                            <ion-icon name="trash"></ion-icon>
                        </ion-button>
                    </ion-item>
                </ion-list>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>
<ion-footer>
    <ion-grid>
        <ion-row>
            <ion-col>
                <ion-button color="tertiary" shape="round" expand="full" (click)="closeModal()" class="slimButton">
                    {{'GENERAL.CLOSE' | translate}}
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>