<ion-header>
</ion-header>
<ion-content [scrollY]="true">
    <form [formGroup]="userForm">
        <ion-grid class="user-form custom-form">

            <ion-row>
                <ion-col><img class="logo"
                        src="https://www.iqscouting.com/wp-content/uploads/2020/11/logo-iqs-white-slogan.svg"></ion-col>
            </ion-row>
            <ion-row class="title">
                <ion-col> <ion-label> {{'AUTH.FORGOT_PASSWORD_TITLE' | translate}}</ion-label></ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <ion-item>
                        <ion-input class="email" type="email" formControlName="userName" required
                            placeholder="{{'AUTH.EMAIL' | translate }}" autocomplete="off">
                        </ion-input>
                    </ion-item>
                </ion-col>
            </ion-row>
            <ion-row class="marginTop20 return-sing-in">
                <ion-col> <a routerLink="/auth"> {{'AUTH.FORGOT_PASSWORD' | translate}}</a></ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <ion-button [disabled]="disabled" (click)="resetPassword()" color="tertiary" shape="round"
                        expand="full" class="slimButton">{{'AUTH.FORGOT_PASSWORD_SEND'
                        | translate | uppercase }}</ion-button>
                </ion-col>
            </ion-row>
            <ion-row class="info">
                <ion-col> <ion-label> {{'AUTH.FORGOT_PASSWORD_INFO' | translate}}</ion-label></ion-col>
            </ion-row>
        </ion-grid>
    </form>
</ion-content>