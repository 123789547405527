import { PlayerDetails } from './../../core/data/player';
import { PlayerSelect } from '../../core/data/player';
import { Component, OnInit, Output } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user.service';
import { NotificationModel } from 'src/app/core/data/user';
import { ModalController, PopoverController } from '@ionic/angular';
import { SharePlayerModalComponent } from './share-player.modal.component';
import { environment } from 'src/environments/environment';
import { NotificationsPopoverComponent } from '../header/notifications.popover.component';
import { ProfileCodes, ProfileType } from 'src/app/core/const/profile';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { getUserEmail, getUserName, getUserProfile } from 'src/app/core/functions/auth-functions';
import { SessionDataService } from 'src/app/core/session/session-data.service';
import { Subscription, take } from 'rxjs';
import { ClipboardService } from 'ngx-clipboard';
import { Clipboard } from '@capacitor/clipboard';
import { Capacitor } from '@capacitor/core';
import { setPlayerRegisterStorage } from 'src/app/core/functions/local-storage-functions';
import { PlayerRegister } from 'src/app/core/data/register';

@Component({
    selector: 'app-menu',
    templateUrl: 'menu.component.html',
    styleUrls: ['menu.component.scss']
})
export class MenuComponent implements OnInit {

    userName: string;
    userEmail: string;
    profile: number;
    profileCode: string;
    @Output() numberNotifications: number = 0;
    @Output() notifications: NotificationModel[];

    //playerId: share
    player: PlayerDetails;
    players: PlayerSelect[];
    isManager = false;

    // Indica si un jugador tiene un perfil de usuario de tipo Jugador.
    hasPlayerProfile = false;
    ProfileType = ProfileType;
    currentApplicationVersion = environment.appVersion;
    subsAfAuth: Subscription = null;

    constructor(public authService: AuthService,
        private router: Router,
        public afAuth: AngularFireAuth,
        private userService: UserService,
        private sessionService: SessionDataService,
        private modalController: ModalController,
        private clipboardService: ClipboardService,
        public popoverController: PopoverController) {
    }

    ngOnDestroy(): void {
        this.subsAfAuth?.unsubscribe();
    }


    ngOnInit() {
        this.subsAfAuth = this.afAuth.idTokenResult.subscribe((user) => {
            if (user) {
                this.profile = getUserProfile(user.claims);
                this.profileCode = ProfileCodes.get(this.profile);
                this.userName = getUserName(user.claims);
                this.userEmail = getUserEmail(user.claims);
            }
        });
    }

    iniData() {
        this.player = this.sessionService.getPlayerDetailsValue();
        if (this.player) {
            this.hasPlayerProfile = this.player.hasPlayerProfile;
        }
    }


    logout(): void {
        this.authService.SignOut();
    }

    goToEdit() {
        const params = {
            state: {
                data: {
                    back: this.router.url
                }
            }
        };
        this.router.navigate(['/player/profile/edit'], params);
    }


    goToAddPlayer() {

        //datos del nuevo jugador
        let playerRegister: PlayerRegister = new PlayerRegister();
        playerRegister.userName = this.userName;
        playerRegister.userEmail = this.userEmail;
        playerRegister.profileId = ProfileType.PARENTS;
        playerRegister.steps = [2]; //Selección de país y estado
        setPlayerRegisterStorage(playerRegister);

        const params = {
            state: {
                data: {
                    back: this.router.url.includes("registration") ? "/feed" : this.router.url
                }
            }
        };
        this.router.navigate(['/player/registration/step-2'], params);
    }



    goHelp() {
        window.open("https://www.iqscouting.com/ayuda");
    }

    async presentPopover(ev: any) {
        let popover1 = await this.popoverController.create({
            component: NotificationsPopoverComponent,
            cssClass: 'my-popover-class',
            event: ev,
            translucent: true,
            componentProps: {
                notifications: this.notifications,
                numberNotifications: this.numberNotifications
            }
        });
        return await popover1.present();
    }

    //share player
    public sharePlayer(toProfile: number) {
        this.getPlayersToShare(toProfile);
    }

    private getPlayersToShare(toProfile: number) {
        this.players = this.sessionService.getUserPlayersValue();
        if (!this.players) {
            this.userService.getPlayersByUser().pipe(take(1)).subscribe(players => {
                this.players = players;
                this.sessionService.updateUserPlayers(players); // guardamos los players obtenidos en la sesion
                this.showShareModal(toProfile);
            });
        }
        else {
            this.showShareModal(toProfile);
        }
    }

    private async showShareModal(toProfile: number) {
        // si somos jugador o si somos padres con un solo hijo
        if (this.players && (this.profile == ProfileType.PLAYER
            || this.profile == ProfileType.PARENTS)) {

            var playersCheckbox = null;
            if (toProfile == ProfileType.PLAYER) {
                // Si pinchamos en dar acceso a jugador, mostramos solo aquellos jugadores que aun no tienen acceso
                playersCheckbox = this.players.filter(x => !x.hasPlayerProfile);
            }
            else {
                // Si pinchamos en dar acceso a padre/madre, mostramos todos los jugadores que tiene vinculados el usuario
                playersCheckbox = this.players;
            }

            const modal = await this.modalController.create({
                component: SharePlayerModalComponent,
                cssClass: 'sharePlayerModal',
                componentProps: {
                    players: playersCheckbox,
                    userName: this.userName,
                    profile: toProfile,
                }
            });
            modal.onDidDismiss().then(x => {
                if (x?.data?.url) {
                    if (Capacitor.isNativePlatform()) {
                        Clipboard.write({
                            string: x.data.url
                        });
                    }
                    else {
                        this.clipboardService.copyFromContent(x.data.url);
                    }
                }
                //this.clipboard.destroy();
            });

            return await modal.present();
        }
    }

}
