import { take } from 'rxjs';
import { ModalController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { ScoreService } from './score.service';
import { PlayerScoreModel } from '../data/score';
import { environment } from 'src/environments/environment';
import { ScoreModalComponent } from 'src/app/core/components/score-modal/score-modal.component';
@Injectable()
export class ScoreNotifierService {
    constructor(private scoreService: ScoreService,
        private modalController: ModalController) { }

    /**
     * Actualizar puntuación del jugador.
     * @param playerId : identificador del jugador
     * @param actions: listatdo de acciones para puntuar 
     * @param showMessage : inidica si se muestra una modal con la puntuación obtenida
     * @param callback : función a ejecutar después de actualizar los puntos
     */
    public updateScore(playerId: string, actions: number[], showMessage: boolean = true, callback?: () => void) {
        //Llamanda al api para actualizar la puntuación del jugador
        if (playerId) {
            if (!environment.production) console.log("playerId", playerId);
            this.scoreService.updatePlayerScore(playerId, actions).pipe(take(1)).subscribe(
                {
                    next: result => {
                        //Mostrar mensaje informando la nueva puntuación
                        if (showMessage) this.showModal(result);

                        //ejecutar función después de actualizar la puntuación
                        if (callback) {
                            callback();
                        }
                        if (!environment.production) console.log("result", result);
                    }
                    , error: e => { if (!environment.production) console.log("error", e); }
                });
        }
    }

    /**
     * Mostrar modal con mensaje con la puntuación obtenida
     * @param score puntuación obtenida
     */
    private async showModal(score: PlayerScoreModel) {
        //Comprobar si se ha ha obtenido alguna puntuación mayor que cero
        if (!environment.production) console.log("score", score);
        if (score.lastActions?.findIndex(s => s.score > 0) >= 0) {
            const modal = await this.modalController.create({
                component: ScoreModalComponent,
                componentProps: { score: score }
            });
            await modal.present();
        }
    }

}



