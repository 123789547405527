import { PillModel, ProgramModel, ProgramModelList, PillSlide } from '../data/pill';
import { FeedBiometricModel, FeedFormSetModel, FeedModelList, FeedProfileModel, FeedTopicModel, FixedModel } from '../data/feed';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';


@Injectable()
export class FeedService {

    constructor(private http: HttpClient) { }

    getFeeds(id: string): Observable<FeedModelList> {
        return this.http.get<FeedModelList>(environment.apiUrl + '/v1/players/' + id + '/feeds');
    }
    getFeedFormSet(id: string): Observable<FeedFormSetModel> {
        return this.http.get<FeedFormSetModel>(environment.apiUrl + '/v1/players/' + id + '/formset-feeds');
    }

    getFeedTopic(id: string): Observable<FeedTopicModel> {
        return this.http.get<FeedTopicModel>(environment.apiUrl + '/v1/players/' + id + '/topic-feeds');
    }

    getFeedWeight(id: string): Observable<FeedBiometricModel> {
        return this.http.get<FeedBiometricModel>(environment.apiUrl + '/v1/players/' + id + '/weight-feeds');
    }

    getFeedHeight(id: string): Observable<FeedBiometricModel> {
        return this.http.get<FeedBiometricModel>(environment.apiUrl + '/v1/players/' + id + '/height-feeds');
    }

    getFeedProfile(id: string): Observable<FeedProfileModel> {
        return this.http.get<FeedProfileModel>(environment.apiUrl + '/v1/players/' + id + '/profile-feeds');
    }

    startFeedEntity(playerId: string, entityId: string): Observable<ProgramModel> {
        return this.http.post<ProgramModel>(environment.apiUrl + '/v1/players/' + playerId + '/entities/' + entityId, {});
    }


    //Program

    getProgramById(programId: string, playerId: string): Observable<ProgramModel> {
        return this.http.get<ProgramModel>(environment.apiUrl + '/v1/players/' + playerId + '/programs/' + programId);
    }
    getPrograms(id: string): Observable<ProgramModelList> {
        return this.http.get<ProgramModelList>(environment.apiUrl + '/v1/players/' + id + '/programs');
    }

    startProgram(playerId: string, programId: string): Observable<ProgramModel> {
        return this.http.post<ProgramModel>(environment.apiUrl + '/v1/players/' + playerId + '/programs/' + programId, {});
    }

    markProgramAsFixed(playerId: string, programId: string): Observable<FixedModel> {
        return this.http.put<FixedModel>(environment.apiUrl + '/v1/players/' + playerId + '/programs/' + programId, {});
    }

    getPillsByProgram(playerId: string, programId: string): Observable<PillModel[]> {
        return this.http.put<PillModel[]>(environment.apiUrl + '/v1/players/' + playerId + '/programs/' + programId + '/pills', {});
    }

    getPillById(id: string, withSlides: boolean): Observable<PillModel> {
        return this.http.get<PillModel>(environment.apiUrl + '/v1/pills-players/' + id + '?withSlides=' + withSlides);
    }

    markPillSlideAsDone(id: string, slide: PillSlide): Observable<PillSlide[]> {
        const params = {
            pillPlayerId: id,
            slide: slide,
        };
        return this.http.put<PillSlide[]>(environment.apiUrl + '/v1/pills-players/' + id + '/slides', params);
    }


}
