import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SignInComponent } from "./sign-in/sign-in.component";
import { SignUpComponent } from "./sign-up/sign-up.component";
import { ForgotPasswordComponent } from "./forgot-password/forgot-password.component";
import { VerifyEmailComponent } from "./email/verify-email.component";
import { ActionManagementComponent } from "./action/action-management.component";
import { AuthRoutingModule } from "./auth-routing.module";
import { ReactiveFormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";
import { IonicModule } from "@ionic/angular";


@NgModule({
    declarations: [SignInComponent,
        SignUpComponent,
        ForgotPasswordComponent,
        VerifyEmailComponent,
        ActionManagementComponent
    ],
    imports: [
        IonicModule,
        TranslateModule,
        CommonModule,
        AuthRoutingModule,
        ReactiveFormsModule,
    ],
    providers: [
    ]
})
export class AuthModule { }