import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { PlayerScoreModel } from "../../data/score";
@Component({
    selector: 'score-modal',
    templateUrl: 'score-modal.component.html',
    styleUrls: ['score-modal.component.scss']
})
export class ScoreModalComponent {
    @Input() score: PlayerScoreModel;
    constructor(public modalController: ModalController) {
    }
    ngOnInit() {
    }

    close() {
        this.modalController.dismiss({
            'dismissed': true
        });
    }

    save() {
        this.modalController.dismiss({
            'dismissed': false
        });
    }
}