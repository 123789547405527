<ion-menu contentId="main" class="menu">
  <ion-header>
    <ion-toolbar color="primary" class="alignCenter">
      <ion-icon name="icon-iqs-lateral" class="whiteFillIcon menuHeaderIcon"></ion-icon>
    </ion-toolbar>
  </ion-header>
  <ion-content>
    <ion-list lines="full">
      <ion-menu-toggle auto-hide="false" *ngIf="profile && profile!=ProfileType.COACH">
        <ion-item class="ion-activatable pointer" (click)="goToEdit()">
          <ion-icon name="icon-football" class="menuIcon secondaryIconFillStrokeColor"></ion-icon>
          <ion-label>{{'PROFILE.EDIT_PLAYER' | translate}}</ion-label>
          <ion-icon class="arrow" name="chevron-forward-outline"></ion-icon>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="false" *ngIf="profile && profile!=ProfileType.COACH && profile!=ProfileType.PLAYER">
        <ion-item class="ion-activatable pointer" (click)="goToAddPlayer()">
          <ion-icon name="person-add-outline" class="menuIcon secondaryIconFillStrokeColor"></ion-icon>
          <ion-label>{{'PROFILE.ADD_PLAYER' | translate}}</ion-label>
          <ion-icon class="arrow" name="chevron-forward-outline"></ion-icon>
        </ion-item>
      </ion-menu-toggle>

      <!-- Dar acceso a padre/madre si soy hijo, o si soy padre y quiero dar acceso a la madre -->
      <ion-menu-toggle auto-hide="false" *ngIf="profile!=ProfileType.COACH ||profile!=ProfileType.COACH">
        <ion-item class="ion-activatable pointer" (click)="sharePlayer(ProfileType.PARENTS)">
          <ion-icon name="share-social-outline" class="menuIcon secondaryIconFillStrokeColor"></ion-icon>
          <ion-label>{{'PROFILE.GIVE_PARENT_ACCESS' | translate}}</ion-label>
          <ion-icon class="arrow" name="chevron-forward-outline"></ion-icon>
        </ion-item>
      </ion-menu-toggle>

      <!-- si somos padre, y además el jugador no tiene acceso a la app (perfil de usuario), podemos compartir la cuenta con el hijo y darle acceso a la app -->
      <ion-menu-toggle auto-hide="false" *ngIf="profile==ProfileType.PARENTS && !hasPlayerProfile">
        <ion-item class="ion-activatable pointer" (click)="sharePlayer(ProfileType.PLAYER)">
          <ion-icon name="football-outline" class="menuIcon secondaryIconFillStrokeColor"></ion-icon>
          <ion-label>{{'PROFILE.GIVE_PLAYER_ACCESS' | translate}}</ion-label>
          <ion-icon class="arrow" name="chevron-forward-outline"></ion-icon>
        </ion-item>
      </ion-menu-toggle>

      <ion-menu-toggle auto-hide="false">
        <ion-item class="ion-activatable pointer" (click)="goHelp()">
          <ion-icon name="help-outline" class="menuIcon secondaryIconFillStrokeColor"></ion-icon>
          <ion-label>{{'GENERAL.HELP' | translate}}</ion-label>
          <ion-icon class="arrow" name="chevron-forward-outline"></ion-icon>
        </ion-item>
      </ion-menu-toggle>
    </ion-list>


    <p class="version">v.{{currentApplicationVersion}}</p>

    <ion-menu-toggle auto-hide="false" class="fixedBottom">
      <ion-item lines="none" color="primary-contrast" class="marginTop10" routerLink="/user/settings"
        routerDirection="root">
        <ion-grid>
          <ion-row>
            <ion-col size="11">
              <ion-grid>
                <ion-row>
                  <ion-label class="user">{{userName}}</ion-label>
                </ion-row>
                <ion-row>
                  <ion-label *ngIf="profileCode" class="profile">{{'PROFILE.CODE_' + profileCode |
                    translate | uppercase }}</ion-label>
                </ion-row>
              </ion-grid>
            </ion-col>
            <ion-col size="1" class="edit-user">
              <ion-icon [display]="true" [tooltip]="('GENERAL.YOUR_ACCOUNT' | translate)"
                name="create-outline"></ion-icon>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>

      <ion-item class="logout" lines="full" (click)="logout()" color="primary-contrast">
        <ion-grid>
          <ion-row>
            <ion-col size="2">
              <ion-icon class="signOffIcon alignMiddle" name="power-outline"></ion-icon>
            </ion-col>
            <ion-col size="8">
              <ion-label class="signOff alignMiddle">{{'GENERAL.SIGN_OFF' | translate}}</ion-label>
            </ion-col>
            <ion-col>
              <ion-icon class="arrowClose alignMiddle" name="chevron-forward-outline"></ion-icon>
            </ion-col>
          </ion-row>
        </ion-grid>
      </ion-item>
    </ion-menu-toggle>
  </ion-content>
</ion-menu>

<ion-router-outlet id="main"></ion-router-outlet>