import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from "@sentry/angular";
import { Integrations } from "@sentry/tracing";

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { registerLicense } from '@syncfusion/ej2-base';

registerLicense(environment.syncfusionLicense);

if (environment.sentry.environment != "local") {

  Sentry.init({
    dsn: environment.sentry.dsn,
    environment: environment.sentry.environment,
    debug: environment.sentry.debug,
    attachStacktrace: environment.sentry.attachStackTrace,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ["localhost", "http://localhost:5001/"],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: 1.0,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
