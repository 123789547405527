import { LocationModel } from 'src/app/core/data/location';

export class PlayerRegister {

    id?: string;
    form?: FromRegister;
    userName?: string;
    userEmail?: string;
    profileId?: number;
    country?: CountryRegister;
    place?: PlaceRegister;
    club?: ClubRegister;
    category?: CategoryRegister;
    gender?: GenderRegister;
    team?: TeamRegister;
    name?: string;
    firstname?: string;
    lastname?: string;
    nickName?: string;
    position?: PositionGroupRegister;
    addressId?: string;
    steps?: number[];

    getPrevStep(step: number): number {
        if (this.steps) {
            const prevSteps = this.steps.filter(s => s < step)
            let prevStep = prevSteps[prevSteps.length - 1];
            return prevStep;
        }

        return 0;
    }

    static getStepNumber(stepName: string): number {
        const match = stepName.match(/step-(\d+)/);
        return parseInt(match[1], 10);
    }
}
export class PositionGroupRegister {
    id?: number;
    code?: string;
}
export class GenderRegister {
    id?: number;
    code?: string;
}
export class CategoryRegister {
    id?: string;
    code?: string;
    description?: string;
    years?: string;
}
export class FromRegister {
    token?: string;
    mode?: string;
    step?: string;
    playerMe?: string;
    team?: string;
}

export class CountryRegister {
    id?: number;
    name?: string;
    code?: string;
    stateId?: number;
    stateName?: string;
}

export class PlaceRegister {
    locations?: LocationModel[];
    placeId?: string;
    placeName?: string;
}

export class ClubRegister {
    id?: string;
    name?: string;
}

export class TeamRegister {
    id?: string;
    name?: string;
}

