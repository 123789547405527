
import { Injectable, NgZone } from '@angular/core';
import { User } from './user';
import * as auth from 'firebase/auth';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import {
    AngularFirestore, AngularFirestoreDocument
} from '@angular/fire/compat/firestore';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { firstValueFrom, take } from 'rxjs';
import { getUserEmail, getUserName, getUserPlayerIds, getUserProfile } from '../core/functions/auth-functions';
import { clearPlayerRegisterStorage, clearStorage, getUrlGameStorage, setUserStorage } from '../core/functions/local-storage-functions';
import { environment } from 'src/environments/environment';
import { SessionDataService } from '../core/session/session-data.service';
import { ProfileType } from '../core/const/profile';
import { ToastNotifierService } from '../core/services/toast-notifier.service';
import { ScoreAction } from '../core/const/scoreActions';


@Injectable({
    providedIn: 'root',
})
export class AuthService {
    userData: any; // Save logged in user data
    previousUrl: string = '';
    constructor(
        public afs: AngularFirestore,
        public afAuth: AngularFireAuth,
        private route: ActivatedRoute,
        public router: Router,
        public ngZone: NgZone,
        private sessionService: SessionDataService,
        private toastNotifierService: ToastNotifierService
    ) {
        /* Saving user data in localstorage when
        logged in and setting up null when logged out */
        this.afAuth.authState.subscribe((user) => {
            if (user) {
                this.userData = user;
                setUserStorage(user);
            } else {
                clearStorage();
                this.sessionService.clearData();
            }
        });
    }

    getPreviousUrl() {
        return this.previousUrl;
    }

    // Sign in with email/password
    SignIn(email: string, password: string) {
        return this.afAuth
            .signInWithEmailAndPassword(email, password).then((result) => {
                this.navigate();
            })
            .catch((error) => {
                if (error.code == "auth/user-not-found") {
                    this.toastNotifierService.failMessage('AUTH.USER_NOT_FOUND');
                }
                else if (error.code == "auth/wrong-password") {
                    this.toastNotifierService.failMessage('AUTH.USER_WRONG_PASSWORD');
                }
                else {
                    if (!environment.production) console.log(error);
                    this.toastNotifierService.failMessage("AUTH.USER_GENERAL_ERROR");
                }
            });
    }
    // Sign up with email/password
    SignUp(email: string, password: string) {
        clearPlayerRegisterStorage();
        return this.afAuth
            .createUserWithEmailAndPassword(email, password)
            .then((result) => {
                /* Call the SendVerificaitonMail() function when new user sign
                up and returns promise */
                this.SendVerificationMail();
                this.navigate();
            })
            .catch((error) => {
                if (error.code == "auth/email-already-in-use") {
                    this.toastNotifierService.failMessage('AUTH.USER_EMAIL_ALREADY_IN_USE');
                }
                else if (error.code == "auth/weak-password") {
                    this.toastNotifierService.failMessage('AUTH.USER_WEAK_PASSWORD');
                }
                else {
                    if (!environment.production) console.log(error);
                    this.toastNotifierService.failMessage("AUTH.USER_GENERAL_ERROR");
                }
            });
    }
    // Send email verfificaiton when new user sign up
    SendVerificationMail() {
        return this.afAuth.currentUser
            .then((u: any) => u.sendEmailVerification())
        /*.then(() => {
            this.router.navigate(['auth/verify-email-address']);
        });*/
    }
    // Reset Forggot password
    ForgotPassword(passwordResetEmail: string) {
        return this.afAuth
            .sendPasswordResetEmail(passwordResetEmail)
            .then(() => {
                this.toastNotifierService.successMessage('AUTH.USER_OK_RESET_PASSWORD');
            })
            .catch((error) => {
                this.toastNotifierService.failMessage('AUTH.USER_KO_RESET_PASSWORD');
            });
    }

    // Sign in with Google
    GoogleAuth() {
        return this.AuthLogin(new auth.GoogleAuthProvider()).then((res: any) => {
            this.getPreviousUrl();
        });
    }
    // Auth logic to run auth providers
    AuthLogin(provider: any) {
        return this.afAuth
            .signInWithPopup(provider)
            .then((result) => {
                this.getPreviousUrl();
                //this.SetUserData(result.user);
            })
            .catch((error) => {
                this.toastNotifierService.failMessage('AUTH.USER_GENERAL_ERROR');
            });
    }
    /* Setting up user data when sign in with username/password,
    sign up with username/password and sign in with social auth
    provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
    SetUserData(user: any) {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(
            `users/${user.uid}`
        );
        const userData: User = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified,
        };
        return userRef.set(userData, {
            merge: true,
        });
    }
    // Sign out
    SignOut() {
        return this.afAuth.signOut().then(() => {
            clearStorage();
            this.sessionService.clearData();
            this.router.navigate(['slides']);
        });
    }
    // Returns true when user is looged in and email is verified
    get isLoggedIn(): boolean {
        const user = JSON.parse(localStorage.getItem('user')!);
        return user !== null;
    }
    async checkAuth() {
        return firstValueFrom(this.afAuth.authState.pipe());
    }

    private navigate() {
        this.afAuth.idTokenResult.pipe(take(1)).subscribe((user) => {
            if (user) {
                const playersIds = getUserPlayerIds(user.claims);
                const queryParams = this.route.snapshot.queryParams;
                const hasPlayers = playersIds && playersIds.length > 0;
                const profile = getUserProfile(user.claims);

                //Compartir jugador
                if (queryParams && queryParams.from && queryParams.token
                    && queryParams.from === 'share') {
                    this.router.navigate(['/share/' + queryParams.token]);
                }
                //Si no tiene jugadores
                else if (!hasPlayers) {
                    if (profile != ProfileType.COACH) {
                        clearPlayerRegisterStorage();
                        const params = this.getStateParams(user, queryParams);
                        this.router.navigate(['/player/registration'], params)
                    }
                    else {
                        this.router.navigate(['/coach/teams'])
                    }
                }
                else {
                    //Rellenar formulario de compañeros
                    if (queryParams && queryParams.token) {
                        const url = '/form/' + queryParams.token;
                        const urlQueryParams = this.getQueryParams(queryParams);
                        this.router.navigate([url], urlQueryParams);
                    }
                    //IQS Quiz
                    else if (queryParams && queryParams.quizToken) {
                        const url = '/quiz-invitation/' + queryParams.quizToken;
                        this.router.navigate([url]);
                    }
                    else {
                        var urlGame = getUrlGameStorage();
                        if (urlGame != null) {
                            this.router.navigate(['/player/games']);
                        } else {
                            profile != ProfileType.COACH
                                ? this.router.navigate(['/feed'])
                                : this.router.navigate(['/coach/teams']);
                        }
                    }
                }
            }
            else {
                clearStorage();
                this.sessionService.clearData();
            }
        });
    }


    private getQueryParams(queryParams) {
        return {
            queryParams: {
                mode: queryParams.mode,
                step: queryParams.step,
                playerMe: queryParams.playerMe
            }
        };
    }

    private getStateParams(user, queryParams: Params) {
        const params = {
            state: {
                data: {
                    token: queryParams ? queryParams.token : null,
                    mode: queryParams ? queryParams.mode : null,
                    step: queryParams ? queryParams.step : null,
                    playerMe: queryParams ? queryParams.playerMe : null,
                    team: queryParams ? queryParams.team : null,
                    userName: getUserName(user.claims),
                    userEmail: getUserEmail(user.claims),
                    back: `/player/registration/step-${0}`,
                }
            }
        };

        return params;
    }
}