import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { validateAllFormFields } from 'src/app/core/functions/function';
import { ToastNotifierService } from 'src/app/core/services/toast-notifier.service';
@Component({
    selector: 'app-sign-in',
    templateUrl: './sign-in.component.html',
    styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {
    loading: boolean;
    userForm: FormGroup;
    disabled: boolean = false;
    constructor(
        public authService: AuthService,
        public afAuth: AngularFireAuth,
        private fb: UntypedFormBuilder,
        private toastNotifierService: ToastNotifierService,
    ) {

        this.userForm = this.fb.group({
            userName: ['', Validators.required],
            userPassword: ['', Validators.required]
        });

    }
    ngOnInit() {
    }

    signIn() {
        this.disabled = true;
        if (this.userForm.valid) {
            let user = { ...this.userForm.value };
            this.authService.SignIn(user.userName, user.userPassword).then((result) => {
                this.disabled = false;
            })
                .catch((error) => {
                    this.disabled = false;
                });

        } else {
            validateAllFormFields(this.userForm);
            this.toastNotifierService.failMessage("ERRORS.ERROR_FILL_REQUIRED_FIELDS");
            this.disabled = false;
        }
    }



}
