import { Component, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import CustomValidators from 'src/app/core/custom-validators';
import { validateAllFormFields } from 'src/app/core/functions/function';
import { ToastNotifierService } from 'src/app/core/services/toast-notifier.service';
@Component({
    selector: 'app-sign-up',
    templateUrl: './sign-up.component.html',
    styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
    userForm: FormGroup;
    disabled: boolean = false;
    constructor(
        public authService: AuthService,
        private fb: UntypedFormBuilder,
        private toastNotifierService: ToastNotifierService) {
        this.userForm = this.fb.group({
            userName: ['', Validators.required],
            userPassword: ['', Validators.required],
            repeatUserPassword: ['', Validators.required],
            aceptLegalPrivacyPolicies: [, Validators.required],
        }, {
            validators: [CustomValidators.match('userPassword', 'repeatUserPassword')]
        });
    }

    ngOnInit() {
    }

    signUp() {
        this.disabled = true;
        if (this.userForm.valid) {
            let user = { ...this.userForm.value };
            this.authService.SignUp(user.userName, user.userPassword).then((result) => {
                this.disabled = false;
            })
                .catch((error) => {
                    this.disabled = false;
                });
        }
        else {
            this.disabled = false;
            validateAllFormFields(this.userForm);
            this.toastNotifierService.failMessage("ERRORS.ERROR_CHECK_FIELDS");
        }
    }

    google() {
        this.authService.GoogleAuth().then(() => {
        }).catch((error) => {
        });
    }

}

