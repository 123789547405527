<section class="s-login signin-form m-auto">
    <div class="container">
        <h2 class="title mt-4 mt-lg-0"><span>Grazas por rexistrate</span></h2>
        <div class="mb-4 text-center"><img src="assets/img/cg-separator.png"></div>
        <div class="card-shadow mb-5">
            <div class="formGroup" *ngIf="authService.userData as user">
                <p class="text-center">Enviamos un correo electrónico a <strong>{{user.email}}</strong>.</p>
                <p class="text-center">Por favor, revisa a túa bandexa de entrada e fai clic na ligazón de
                    verificación. Se non recibes o correo electrónico, revisa a túa bandexa de correo non desexado.</p>
            </div>

            <!-- Calling SendVerificationMail() method using authService Api -->
            <div class="formGroup">
                <button type="button" class="btn btnPrimary" (click)="authService.SendVerificationMail()">
                    <i class="fas fa-redo-alt"></i>
                    Reenviar correo de verificación
                </button>
            </div>
            <div class="redirectToLogin">
                <span>Volver a <span class="redirect" routerLink="/auth/login"> Entrar</span></span>
            </div>
        </div>
    </div>
</section>