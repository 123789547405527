
import { HttpClient } from '@angular/common/http';
import { Injectable, SkipSelf } from '@angular/core';
import { CreateClubDetails } from '../data/club';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ClubService {

    constructor(private http: HttpClient) { }

    addClub(club: CreateClubDetails): Observable<string> {
        return this.http.post<string>(environment.apiUrl + '/v1/clubs', club);
    }

    getClubById(id: string): Observable<CreateClubDetails> {
        return this.http.get<CreateClubDetails>(environment.apiUrl + '/v1/clubs/' + id + '/location');
    }

    getCodeForClubId(id: string): Observable<any> {
        return this.http.get<any>(environment.apiUrl + '/v1/clubs/' + id + '/prescriptor-code');
    }

    getCountries(): Observable<any> {
        return this.http.get<any>(environment.apiUrl + '/v1/countrymodels');
    }

    getStatesByCountryId(countryId: number): Observable<any> {
        return this.http.get<any>(environment.apiUrl + '/v1/countries/' + countryId + '/states');
    }
}
