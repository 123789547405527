<ion-header>
</ion-header>
<ion-content [scrollY]="true">
    <ion-grid class="user-form custom-form" [formGroup]="userForm" *ngIf="mode == 'resetPassword'">
        <ion-row>
            <ion-col><img class="logo"
                    src="https://www.iqscouting.com/wp-content/uploads/2020/11/logo-iqs-white-slogan.svg"></ion-col>
        </ion-row>
        <ion-row class="title">
            <ion-col> <ion-label> {{'AUTH.RESET_PASSWORD_TITLE' | translate}}</ion-label></ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-item>
                    <ion-input class="password" formControlName="userPassword" type="password" required
                        placeholder="{{'AUTH.PASSWORD' | translate }}" autocomplete="off">
                    </ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-item>
                    <ion-input class="password" formControlName="repeatUserPassword" type="password" required
                        placeholder="{{'AUTH.CONFIRM_PASSWORD' | translate }}" autocomplete="off">
                    </ion-input>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row>
            <ion-col>
                <ion-button [disabled]="disabled" (click)="changePassword()" color="tertiary" shape="round"
                    expand="full" class="slimButton">{{'AUTH.RESET_PASSWORD'
                    | translate | uppercase }}</ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
    <ion-grid class="user-form custom-form" *ngIf="mode == 'verifyEmail'">
        <ion-row>
            <ion-col><img class="logo"
                    src="https://www.iqscouting.com/wp-content/uploads/2020/11/logo-iqs-white-slogan.svg"></ion-col>
        </ion-row>
        <ion-row class="title">
            <ion-col> <ion-label> {{'AUTH.VERIFY_EMAIL' | translate}}</ion-label></ion-col>
        </ion-row>
        <ion-row class="marginTop20 info">
            <ion-col> <ion-label> {{('AUTH.VERIFY_EMAIL_OK_1' | translate) +' '}}<strong>{{userEmail}}</strong>
                    {{('AUTH.VERIFY_EMAIL_OK_2' | translate) +' '}}</ion-label></ion-col>
        </ion-row>
        <ion-row class="marginTop20">
            <ion-col>
                <a routerLink="/auth">{{'AUTH.FORGOT_PASSWORD_RETURN_SING_IN'
                    | translate | uppercase }}</a>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-content>