import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, map } from 'rxjs';
import { AuthService } from './auth.service';
import { setUrlGameStorage } from '../core/functions/local-storage-functions';
import { getUserProfile } from '../core/functions/auth-functions';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ProfileType } from '../core/const/profile';

@Injectable({
    providedIn: 'root'
})

export class AuthGuard {

    constructor(
        public afAuth: AngularFireAuth,
        public authService: AuthService,
        public router: Router,
    ) { }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        return this.afAuth.idTokenResult.pipe(
            map(userData => {
                if (userData == null) {
                    if (!state.url.includes("auth")
                        && !(state.url.indexOf('/quiz-invitation') >= 0)
                        && !(state.url.indexOf('/preview-pill') >= 0)) {
                        this.navigate(state);
                    }
                    else {
                        return true;
                    }
                }

                if (state?.url?.indexOf('player/games') >= 0) {
                    let urlGame = 'player/games';
                    setUrlGameStorage(urlGame);
                }
                //Si la página tiene permisos validar si los cumple
                const requiredPermissions = next?.data?.permissions?.only;
                if (requiredPermissions) {
                    const redirectTo = next?.data?.permissions?.redirectTo;
                    return this.checkUserPermissions(userData, requiredPermissions, redirectTo);
                }
                return true;

            }));
    }

    private navigate(state: RouterStateSnapshot) {
        if (state.root?.queryParams && Object.keys(state.root.queryParams).length > 0) {
            this.router.navigate([state.url]);
        }
        else {
            this.router.navigate(['/slides']);
        }
    }

    private checkUserPermissions(user, targetPermissions: number[], redirectTo: string) {
        let profile = getUserProfile(user.claims);
        let isOk = false;
        if (targetPermissions) {
            //Comprobar si el usuario tiene alguno de los perfiles permitidos
            let permissionArray = Array.isArray(targetPermissions) ? targetPermissions : [targetPermissions];
            isOk = profile ? permissionArray.includes(profile) : permissionArray.includes(ProfileType.NONE);
        }

        if (isOk) {
            return true;
        }
        else {
            if (redirectTo)
                this.router.navigate([redirectTo]);//página especifica
            else {
                this.authService.SignOut();
                this.router.navigate(['/slides']); //pagina por defecto
            }

            return false;
        }
    }

}