import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AppActionFilter } from '../data/filters/appActionFilter';
import { PaginatedFilter } from '../data/filters/paginatedFilter';
import { PlayerScoreModel } from '../data/score'

@Injectable({
    providedIn: 'root'
})
export class ScoreService {

    constructor(private http: HttpClient) { }

    updatePlayerScore(playerId: string, actions: number[]): Observable<PlayerScoreModel> {
        return this.http.put<PlayerScoreModel>(environment.apiUrl + `/v1/player/${playerId}/player-scores`, actions);
    }

    getScoreMovementsByPlayer(playerId: string, appActionFilter: AppActionFilter, paginatedFilter: PaginatedFilter) {
        var params = this.getActionParamsFilter(appActionFilter, paginatedFilter);
        return this.http.get<any>(environment.apiUrl + '/v1/players/' + playerId + '/actions', { params: params });
    }

    //Puntuación
    getTotalScoreByPlayer(playerId: string) {
        return this.http.get<any>(environment.apiUrl + '/v1/players/' + playerId + '/total-score');
    }

    //Clasification
    getClassificationPlayer(playerId: string, classificationFilter: number) {
        var params = this.getClassificationFilter(classificationFilter);
        return this.http.get<any>(environment.apiUrl + '/v1/players/' + playerId + '/classification', { params: params });
    }
    private getActionParamsFilter(appActionFilter, paginatedFilter) {
        let params: HttpParams = new HttpParams();
        if (appActionFilter != null) {

            if (appActionFilter.lastCreatedDate != null) {
                params = params.append("AppActionFilter.LastCreatedDate", appActionFilter.lastCreatedDate);
            }
        }

        if (paginatedFilter != null) {

            if (paginatedFilter.sortField != null) {
                params = params.append("PaginatedFilter.SortField", paginatedFilter.sortField);
            }
            if (paginatedFilter.sortOrder != null) {
                params = params.append("PaginatedFilter.SortOrder", paginatedFilter.sortOrder);
            }
            if (paginatedFilter.first != null && paginatedFilter.rows != null) {
                let pageIndexCalculated = (paginatedFilter.first / paginatedFilter.rows) + 1;
                params = params.append("PaginatedFilter.PageIndex", paginatedFilter.first);
                paginatedFilter.rows = paginatedFilter.rows ? paginatedFilter.rows : 10;
                params = params.append("PaginatedFilter.PageSize", paginatedFilter.rows);
            }
            if (paginatedFilter.globalFilter != null) {
                params = params.append("PaginatedFilter.GlobalFilter", paginatedFilter.globalFilter);
            }
        }

        return params;
    }

    private getClassificationFilter(classificationFilter) {
        let params: HttpParams = new HttpParams();
        if (classificationFilter != null) {
            params = params.append("classificationFilter.ClassificationType", classificationFilter);

        }

        return params;
    }

}
