<ion-header translucent>
  <ion-toolbar>
    <ion-title>{{'PROFILE.SHARE_PLAYER' | translate }}</ion-title>
    <ion-buttons slot="end">
      <ion-icon name="close-outline" (click)="close()" class="fontsize24"></ion-icon>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<ion-content>
  <ion-grid>
    <ion-row>
      <ion-col class="marginTop20 ion-padding">
        <ion-label *ngIf="players.length==1" class="alignLeft">{{'PROFILE.SHARE_PLAYER_DESC_1' | translate}}</ion-label>
        <ion-label *ngIf="players.length>1" class="alignLeft">{{'PROFILE.SHARE_PLAYER_DESC_2' | translate}}</ion-label>
      </ion-col>
    </ion-row>
    <ion-row class="marginTop20">
      <ion-col size="12">
        <ion-list *ngIf="players.length>1">
          <ion-item *ngFor="let p of players" (click)="checkPlayer(p)" class="pointer">
            <ion-checkbox color="tertiary" labelPlacement="start" justify="space-between">{{p.name}}</ion-checkbox>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>

    <ion-row offset-3 class="marginTop20 marginBottom20">

      <!-- whatsapp -->
      <ion-col class="alignCenter pointer shareIcon" (click)="share(WHATSAPP)">
        <ion-icon name="icon-whatsapp" class="secondaryIconColor mediumIcon displayBlock marginAuto"></ion-icon>
        <ion-label class="secondaryColor sendLabelSmall">{{'GENERAL.SHARE_BY' | translate }}</ion-label>
      </ion-col>

      <!-- copiar enlace -->
      <ion-col class="alignCenter pointer shareIcon" (click)="share(CLIPBOARD)">
        <ion-icon name="icon-link" class="secondaryIconColor mediumIcon displayBlock marginAuto"></ion-icon>
        <ion-label class="secondaryColor sendLabelSmall">{{'FORMS.COPY_URL' | translate }}</ion-label>
      </ion-col>

      <!-- email -->
      <ion-col class="alignCenter pointer shareIcon" (click)="sendByEmail()">
        <ion-icon name="icon-mail" class="secondaryIconColor mediumIcon displayBlock marginAuto"></ion-icon>
        <ion-label class="secondaryColor sendLabelSmall">{{'GENERAL.SEND_BY' | translate }}</ion-label>
      </ion-col>

    </ion-row>
  </ion-grid>

</ion-content>