import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BlankComponent } from './layouts/blank/blank.component';
import { SlidesPage } from './pages/slides/slides.page';
import { MenuComponent } from './layouts/menu/menu.component';
import { CodeComponent } from './code/code.component';
import { AuthGuard } from './auth/auth.guard';
import { AutologinComponent } from './autologin/autologin.component';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('src/app/auth/auth.module').then((m) => m.AuthModule),
    canActivate: [AuthGuard],
  },

  {
    path: 'code',
    component: BlankComponent,
    children: [
      {
        path: '',
        component: CodeComponent
      }
    ]
  },
  {
    path: 'slides/:token',
    component: BlankComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: SlidesPage
      }
    ]
  },
  {
    path: 'slides',
    component: BlankComponent,
    children: [
      {
        path: '',
        component: SlidesPage
      }
    ]
  },
  {
    path: 'autologin',
    component: AutologinComponent
  },
  {
    path: 'form',
    component: BlankComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/forms/dynamic-form/dynamic-form.module').then(m => m.DynamicFormModule)
      }
    ]
  },
  {
    path: 'quiz-invitation',
    component: BlankComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/quizzes/quizzes.module').then(m => m.QuizzesModule)
      }
    ]
  },
  {
    path: 'share',
    component: BlankComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/share-player/share-player.module').then(m => m.SharePlayerModule)
      }
    ]
  },

  {
    path: 'info',
    component: MenuComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/info/info.module').then(m => m.InfoModule)
      }
    ]
  },
  {
    path: '',
    component: MenuComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./layouts/tabs/tabs.module').then(m => m.TabsModule)
      }
    ],
    canActivate: [AuthGuard],
  }
];


@NgModule({
  imports: [
    RouterModule.forRoot(routes, { initialNavigation: 'disabled', scrollPositionRestoration: 'enabled' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
