
import { take } from 'rxjs';
import { Injectable } from '@angular/core';
import { ProfileService } from '../services/profile.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ProfileType } from '../const/profile';
import { getDefaultPlayerId, getUserEmail, getUserName, getUserProfile } from '../functions/auth-functions';
import { SessionDataService } from './session-data.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { SharedService } from '../services/shared.service';

@Injectable({
    providedIn: 'root'
})
export class SessionLoadDataService {

    constructor(
        private playerService: ProfileService,
        private userService: UserService,
        public afAuth: AngularFireAuth,
        public sessionDataService: SessionDataService,
        private sharedService: SharedService,
        private router: Router,
    ) {

    }

    /**
     * Actualiza los datos de la sesión
     * @param refreshPlayerDetails indica si es necesario actualizar los datos de la sesión del jugador "playerDetails"
     * @param callback funcion a ejecutar después de actualizar los datos de la sesión
     */
    refreshSessionData(refreshPlayerDetails: boolean, callback: () => void) {
        this.afAuth.idTokenResult.pipe(take(1)).subscribe((user) => {
            if (user) {
                const profile = getUserProfile(user.claims);
                //Comprobar el perfil del usario para actualizar los datos del jugador o entrenador
                if (profile == ProfileType.COACH) {
                    this.loadCoachData(user, callback)
                }
                else {
                    this.loadPlayerData(user, refreshPlayerDetails, callback)
                }
            }
            else {
                //Si no hay usario logueado
                //Borrar los datos de la sesión
                this.sessionDataService.clearData();
                if (this.router.url.indexOf('quiz-invitation/'))
                    callback();
            }
        });

    }

    /**
     * Actualiza los datos de la sesión del jugador seleccionado
     * @param user usuario logueado
     * @param refreshPlayerDetails indica si es necesario actualizar datos detallados del jugador: "playerDetails"
     * @param callback funcion a ejecutar después de actualizar los datos de la sesión
     */
    private loadPlayerData(user, refreshPlayerDetails: boolean, callback: () => void) {
        const profile = getUserProfile(user.claims);
        let playerId = this.sessionDataService.getPlayerIdValue();
        // si hay player en la sesion
        if (playerId) {
            const playerDetails = this.sessionDataService.getPlayerDetailsValue();
            if (refreshPlayerDetails && (!playerDetails || playerDetails.id != playerId)) {
                this.playerService.getPlayerDetails(playerId).pipe(take(1)).subscribe({
                    next: result => {
                        // guardamos el player en la sesion
                        this.sessionDataService.updatePlayerDetails(result);
                        callback();
                    }
                    , error: err => {
                        if (!environment.production) console.log("loadPlayerData", err);
                    }
                });
            }
            else {
                callback();
            }
        }
        else {
            //si hay palyer en el localStorage
            //obtener el jugador por defecto del usario logueado
            playerId = getDefaultPlayerId(user.claims);
            if (playerId) {
                //Comprobar si es necesario actualizar los detalles del jugador
                if (refreshPlayerDetails) {
                    this.loadPlayerDetails(playerId, callback);

                }
                else {
                    this.sessionDataService.updatePlayerId(playerId);
                    callback();
                }
            }
            //Si el usario logueado no tiene níngún jugador y no es un "COACH"
            else if (profile != ProfileType.COACH) {
                const params = {
                    state: {
                        data: {
                            userName: getUserName(user.claims),
                            userEmail: getUserEmail(user.claims)
                        }
                    }
                };
                // redirigir a completar perfil si no tiene jugador
                const currentRoute = this.router.routerState.snapshot.url;
                if (!currentRoute.includes('/player/resgistration')
                    && !currentRoute.includes('/player/profile/complete'))
                    this.router.navigate(['/player/resgistration'], params);
            }
            else { callback(); }
        }
    }

    /**
     * Acutalizar los datos detallados del jugador
     * LLamar al api para obtener los detalles del jugador
     * @param playerId identificador del jugador
     * @param callback funcion a ejecutar después de actualizar los datos de la sesión
     */
    private loadPlayerDetails(playerId, callback: () => void) {
        //Llamada al api para obtener los datos del jugador
        this.playerService.getPlayerDetails(playerId).pipe(take(1)).subscribe({
            next: result => {
                this.sessionDataService.updatePlayerDetails(result);
                this.sessionDataService.updatePlayerId(playerId);
                callback();
            }
            , error: err => {
                if (!environment.production) console.log("loadPlayerDetails", err);
            }
        });
    }

    /**
     * Actualiza los datos de la sessión del perfil entrenador
     * @param user usuario logueado
     * @param callback funcion a ejecutar después de actualizar los datos de la sesión
     */
    private loadCoachData(user, callback: () => void) {

        // si hay player en la sesion
        let coach = this.sessionDataService.getCoachDetails();
        if (coach) {
            //this.loadPlayerData(user, refreshPlayerDetails, callback);
        }
        else {
            //Obtener el entrenador asociado al perfil y guardarlo
            this.userService.getCoachByUser().pipe(take(1)).subscribe({
                next: coach => {
                    this.sessionDataService.updateCoachDetails(coach);
                    callback();
                    //this.loadPlayerData(user, refreshPlayerDetails, callback);
                }, error: err => {
                    if (!environment.production) console.log(err);
                }
            });
        }

    }
}








