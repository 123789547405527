
import { Subject, Observable } from 'rxjs';
import { PlayerDetails, PlayerSelect } from '../data/player';
import { Injectable } from '@angular/core';
import { CoachDetails } from '../data/coach';
import { setHasCoahPlayerStorage, setLastPlayerStorage } from '../functions/local-storage-functions';


@Injectable({
    providedIn: 'root'
})
export class SessionDataService {

    private subjCoach = new Subject<CoachDetails>();
    private subjPlayerDetails = new Subject<PlayerDetails>();
    private subjPlayerId = new Subject<string>();
    private subjUserPlayers = new Subject<PlayerSelect[]>();
    private coahDetails: CoachDetails;
    private playerDetails: PlayerDetails;
    private playerId: string;
    private playerImage: string;
    private userPlayers: PlayerSelect[];

    constructor() {

    }


    //COACH
    public getCoachDetails(): Observable<CoachDetails> {
        return this.subjCoach.asObservable();
    }
    public getCoachValue(): CoachDetails {
        return this.coahDetails;
    }
    public updateCoachDetails(coach: CoachDetails): void {
        this.coahDetails = coach;
        this.subjCoach.next(coach);
    }

    //PLAYER
    public getPlayerDetails(): Observable<PlayerDetails> {
        return this.subjPlayerDetails.asObservable();
    }
    public getPlayerDetailsValue(): PlayerDetails {
        return this.playerDetails;
    }
    public updatePlayerDetails(player: PlayerDetails): void {
        this.playerDetails = player;
        setHasCoahPlayerStorage(player?.hasCoach);
        this.subjPlayerDetails.next(player);
    }
    public updatePlayerHeight(playerId, height) {
        var player = this.getPlayerDetailsValue();
        if (player && player.id == playerId) {
            player.height = height;
            this.updatePlayerDetails(player);
        }
    }
    public updatePlayerWeight(playerId, weight) {
        var player = this.getPlayerDetailsValue();
        if (player && player.id == playerId) {
            player.weight = weight;
            this.updatePlayerDetails(player);
        }
    }

    public getPlayerId(): Observable<string> {
        return this.subjPlayerId.asObservable();
    }
    public getPlayerIdValue(): string {
        return this.playerId;
    }
    public updatePlayerId(playerId: string): void {
        this.playerId = playerId
        setLastPlayerStorage(playerId)
        this.subjPlayerId.next(playerId);
    }

    public getPlayerImageValue(): string {
        return this.playerImage;
    }
    public updateImage(img: string) {
        this.playerImage = img;
    }

    public updatePlayerImage(playerId: string, img: string) {
        if (this.playerDetails?.id == playerId) {
            this.playerDetails.decodeImage = img;
            this.subjPlayerDetails.next(this.playerDetails);
        }
        if (this.userPlayers) {
            const index = this.userPlayers.findIndex(up => up.id == playerId);
            if (index >= 0) {
                this.userPlayers[index].decodeImage = img;
                this.updateUserPlayers(this.userPlayers);
            }
        }
    }




    //USER PLAYERS
    public getUserPlayers(): Observable<PlayerSelect[]> {
        return this.subjUserPlayers.asObservable();
    }
    public getUserPlayersValue(): PlayerSelect[] {
        return this.userPlayers;
    }
    public updateUserPlayers(players: PlayerSelect[]): void {
        this.userPlayers = players;
        this.subjUserPlayers.next(players);
    }

    public clearData() {
        this.updateCoachDetails(null);
        this.updatePlayerDetails(null);
        this.updatePlayerId(null);
        this.updateImage(null);
        this.updateUserPlayers(null);

    }

}
