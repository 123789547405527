import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class AssetsService {
    constructor(private http: HttpClient) { }
    createToken(): Observable<any> {
        return this.http.post<any>(environment.apiUrl + '/v1/tokens', {});
    }

    getRoles(token: string): Observable<any> {
        return this.http.get<any>(environment.apiUrl + '/v1/roles?token=' + token);
    }


}