import { PaymentService } from './core/services/payment.service';
import { NgModule, LOCALE_ID, ErrorHandler, Injectable, APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent, createTranslateLoader } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BlankComponent } from './layouts/blank/blank.component';
import { TokenInterceptor } from './auth/auth.interceptor';
import { AuthGuard } from './auth/auth.guard';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TooltipModule } from 'ng2-tooltip-directive';
import { SlidesPage } from './pages/slides/slides.page';
import { RatingModule } from 'primeng/rating';
import { ProgressBarModule } from 'primeng/progressbar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormsService } from './core/services/forms.service';
import { ProfileService } from './core/services/profile.service';
import { MenuComponent } from './layouts/menu/menu.component';
import { SharePlayerModalComponent } from './layouts/menu/share-player.modal.component';
import { UserService } from './core/services/user.service';
import { CodeComponent } from './code/code.component';
import { CoachService } from './core/services/coach.service';
import { AuthModule } from './auth/auth.module';

import * as Sentry from '@sentry/angular';

// locales
import { registerLocaleData, CommonModule } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import localeExtraEs from '@angular/common/locales/extra/es';
import { LoggingService } from './core/services/logging.service';
import { AutologinComponent } from './autologin/autologin.component';
import { TeamService } from './core/services/team.service';
import { MasterService } from './core/services/master.service';
import { ClubService } from './core/services/club.service';
import { BiometricService } from './core/services/biometric.service';
import { SetService } from './core/services/set.service';
import { ToastNotifierService } from './core/services/toast-notifier.service';
import { SharedService } from './core/services/shared.service';
import { ClipboardModule } from 'ngx-clipboard';
import { FeedService } from './core/services/feed.service';
import { PlanService } from './core/services/plan.service';
import { SubscriptionService } from './core/services/subscription.service';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { FIREBASE_OPTIONS } from '@angular/fire/compat';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { AssetsService } from './core/services/assets.service';
import { AuthService } from './auth/auth.service';
import { SessionLoadDataService } from './core/session/session-load.service';
import { SessionDataService } from './core/session/session-data.service';
import { PlayerFormsService } from './core/services/player-forms.service';
import { ScoreService } from './core/services/score.service';
import { customAnimation } from './core/animations';
import { ScoreNotifierService } from './core/services/score-notifier.service';
import { GlobalErrorHandler } from './core/global-error.handler';
//let resolvePersistenceEnabled: (enabled: boolean) => void;

/*const appInitializerFn = (appConfig: ConfigService, translate: TranslateService, injector: Injector) => {
  return () => appConfig.loadAppConfig(translate, injector);
};*/

registerLocaleData(localeEs, 'es-ES', localeExtraEs);

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() { }
  handleError(error) {
    if (environment.sentry.environment != "local") {
      const eventId = Sentry.captureException(error.originalError || error);
      if (environment.sentry.dsn) {
        Sentry.showReportDialog({ eventId });
      }
    }
    console.log(error);
  }
}



@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    BlankComponent,
    MenuComponent,
    SharePlayerModalComponent,
    SlidesPage,
    AutologinComponent,
    CodeComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot({ navAnimation: customAnimation }),
    BrowserAnimationsModule,
    AppRoutingModule,
    ServiceWorkerModule.register('combined-sw.js', { enabled: environment.production }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    TooltipModule,
    RatingModule,
    ProgressBarModule,
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    AuthModule,
    ClipboardModule,
    provideFirebaseApp(() => initializeApp(environment.firebase)),
  ],
  providers: [
    AuthService,
    AuthGuard,
    StatusBar,
    Title,
    SplashScreen,
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => { },
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost', 9099] : undefined },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
    FormsService,
    ProfileService,
    AssetsService,
    FeedService,
    MasterService,
    ClubService,
    TeamService,
    UserService,
    PlanService,
    PaymentService,
    SubscriptionService,
    SetService,
    LoggingService,
    SharedService,
    SessionDataService,
    SessionLoadDataService,
    CoachService,
    ToastNotifierService,
    ScoreNotifierService,
    BiometricService,
    PlayerFormsService,
    ScoreService
  ],
  bootstrap: [AppComponent],

})
export class AppModule {
  constructor() {
  }
}
