
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SetData } from '../data/form';

@Injectable()
export class FormsService {

    constructor(private http: HttpClient) { }

    getFormByToken(token: string): Observable<any> {
        const param = { token: token };
        return this.http.get<any>(environment.apiUrl + '/v1/forms', { params: param });
    }

    getFormSetByToken(token: string, playerTargetId: string): Observable<any> {
        const param = { token: token, playerTargetId: playerTargetId };
        return this.http.get<any>(environment.apiUrl + '/v1/formsets', { params: param });
    }

    getFormSetHeaderByToken(token: string, playerTargetId: string): Observable<SetData> {
        const param = { token: token, playerTargetId: playerTargetId };
        return this.http.get<SetData>(environment.apiUrl + '/v1/forms-headers', { params: param });
    }

    sentForm(formid: string, mode: number, name: string, email: string): Observable<any> {
        const params = {
            id: formid,
            mode,
            name,
            email
        };
        return this.http.put<any>(environment.apiUrl + '/v1/forms/sent', params);
    }

    //Actualizar el Form
    updatePercentageForm(id: string, percentage: number): Observable<any> {
        return this.patchForm(id, percentage, percentage == 100);
    }

    finishForm(id: string): Observable<any> {
        return this.patchForm(id, 100, true);
    }

    patchForm(id: string, percentage: number, isFull: boolean): Observable<any> {
        var updateObject = [];
        if (percentage) {
            var optionPercentage = { "path": "PercentCompleted", "op": "replace", "value": percentage };
            updateObject.push(optionPercentage);
        }

        if (isFull) {
            var optionIsFull = { "path": "IsFull", "op": "replace", "value": isFull };
            updateObject.push(optionIsFull);
        }

        return this.http.patch<any>(environment.apiUrl + '/v1/forms/' + id, updateObject);
    }



}
