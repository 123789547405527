import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppComponent } from 'src/app/app.component';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import CustomValidators from 'src/app/core/custom-validators';
import { validateAllFormFields } from 'src/app/core/functions/function';
import { ToastNotifierService } from 'src/app/core/services/toast-notifier.service';

@Component({
    selector: 'app-action-management',
    templateUrl: './action-management.component.html',
    styleUrls: ['./action-management.component.scss']
})
export class ActionManagementComponent implements OnInit {
    loading: boolean;
    userForm: FormGroup;
    code: string;
    mode: string;
    userEmail: string;
    disabled: boolean = false;
    constructor(
        public authService: AuthService,
        public afAuth: AngularFireAuth,
        public app: AppComponent,
        private fb: UntypedFormBuilder,
        private route: ActivatedRoute,
        private toastNotifierService: ToastNotifierService,
    ) {

        this.userForm = this.fb.group(
            {
                userPassword: ['', Validators.required],
                repeatUserPassword: ['', Validators.required]
            }, {
            validators: [CustomValidators.match('userPassword', 'repeatUserPassword')]
        });
    }
    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            this.code = params['oobCode'];
            this.mode = params['mode'];
        });

    }

    changePassword() {
        this.disabled = true;
        if (this.userForm.valid) {
            let user = { ...this.userForm.value };
            this.afAuth.confirmPasswordReset(this.code, user.userPassword)
                .then((result) => {
                    this.disabled = false;
                    this.toastNotifierService.successMessage("AUTH.RESET_PASSWORD_OK")
                })
                .catch((error) => {
                    this.disabled = false;
                    this.toastNotifierService.failMessage("ERRORS.USER_KO_RESET_PASSWORD");
                });


        } else {
            this.disabled = false;
            validateAllFormFields(this.userForm);
            this.toastNotifierService.failMessage("ERRORS.ERROR_CHECK_FIELDS");
            this.loading = false;
        }
    }


}