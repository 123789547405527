<ion-header>
</ion-header>
<ion-content [scrollY]="true">
    <form [formGroup]="userForm">
        <ion-grid class="user-form custom-form">
            <ion-row>
                <ion-col><img class="logo"
                        src="https://www.iqscouting.com/wp-content/uploads/2020/11/logo-iqs-white-slogan.svg"></ion-col>
            </ion-row>
            <ion-row class="title">
                <ion-col> <ion-label> {{'AUTH.SIGN_UP_TITLE' | translate}}</ion-label></ion-col>
            </ion-row>
            <ion-row>
                <ion-col size="12">
                    <ion-item>
                        <ion-input class="email" type="email" formControlName="userName" required
                            placeholder="{{'AUTH.EMAIL' | translate }}" autocomplete="off">
                        </ion-input>
                    </ion-item>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col size="12">
                    <ion-item>
                        <ion-input class="password" formControlName="userPassword" type="password" required
                            placeholder="{{'AUTH.PASSWORD' | translate }}" autocomplete="off">
                        </ion-input>
                    </ion-item>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col size="12">
                    <ion-item>
                        <ion-input class="password" formControlName="repeatUserPassword" type="password" required
                            placeholder="{{'AUTH.CONFIRM_PASSWORD' | translate }}" autocomplete="off">
                        </ion-input>
                    </ion-item>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col size="12" class="ion-text-left">
                    <ion-checkbox formControlName="aceptLegalPrivacyPolicies" labelPlacement="end" required>
                    </ion-checkbox><ion-label class="legal-plocies"> {{('AUTH.ACEPT'| translate) +' '}} <a
                            target="_blank" href="https://www.iqscouting.com/aviso-legal/"> {{'AUTH.LEGAL' |
                            translate}}</a>
                        {{('AUTH.AND_THE'| translate ) +' '}} <a target="_blank"
                            href="https://www.iqscouting.com/politica-de-privacidad/">
                            {{'AUTH.PRIVACY_POLICY' |
                            translate}}</a></ion-label>
                </ion-col>
            </ion-row>
            <ion-row class="marginTop20">
                <ion-col>
                    <ion-button [disabled]="disabled" (click)="signUp()" color="tertiary" shape="round" expand="full"
                        class="slimButton">{{'AUTH.SIGN_IN' | translate | uppercase }}</ion-button>
                </ion-col>
            </ion-row>
            <ion-row class="marginTop20 info-sing-in-up">
                <ion-col>{{'AUTH.HAS_ACCOUNT' | translate | uppercase }} <a routerLink="/auth">
                        {{'AUTH.SIGN_IN' | translate| uppercase}}</a></ion-col>
            </ion-row>
        </ion-grid>
    </form>
</ion-content>