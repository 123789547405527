export const ScoreAction =
{
    AppLogin: 1,
    AddMate: 2,
    SentQuestionnaire: 3,
    GetQuestionnaire: 4,
    MateRegistered: 5,
    AddHeight: 6,
    AddWeight: 7,
    FinishPill: 8,
    FinishTopic: 9,
    FinishLessonUnit: 10,
    FinishLearningModule: 11,
    KahootQuestionMaxOK: 12,
    KahootQuestionKO: 13,
    KahootParamTime: 14,
    KahootQuestionMinOK: 14,
    ExamOK: 16,
    ExamKO: 17
}

