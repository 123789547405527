
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { PlayerPosition, PositionGroup } from '../data/position';
import { CategorySelect, Gender } from '../data/team';
import { LanguageModel } from '../data/language';

@Injectable({
    providedIn: 'root'
})
export class MasterService {

    constructor(private http: HttpClient) { }

    getPositions(): Observable<PlayerPosition[]> {
        return this.http.get<PlayerPosition[]>(environment.apiUrl + '/v1/positions');
    }
    getPositonGroups(): Observable<PositionGroup[]> {
        return this.http.get<PositionGroup[]>(environment.apiUrl + '/v1/position-groups');
    }

    getCategories(placeId: string): Observable<CategorySelect[]> {
        return this.http.get<CategorySelect[]>(environment.apiUrl + '/v1/categories?placeId=' + placeId);
    }

    getCategoriesByClub(clubId: string): Observable<CategorySelect[]> {
        return this.http.get<CategorySelect[]>(environment.apiUrl + '/v1/clubs/' + clubId + '/categories');
    }

    getLanguages(): Observable<LanguageModel[]> {
        return this.http.get<LanguageModel[]>(environment.apiUrl + '/v1/languages');
    }

    getGenders(): Observable<Gender[]> {
        return this.http.get<Gender[]>(environment.apiUrl + '/v1/genders');
    }
}
