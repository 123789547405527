import { Observable } from "rxjs";
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Height, Weight } from '../data/biometric';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class BiometricService {

    constructor(private http: HttpClient) { }

    getHeightsByPlayerId(playerId: string): Observable<Height[]> {
        return this.http.get<Height[]>(environment.apiUrl + '/v1/players/' + playerId + '/heights');
    }

    getWeightsByPlayerId(playerId: string): Observable<Weight[]> {
        return this.http.get<Weight[]>(environment.apiUrl + '/v1/players/' + playerId + '/weights');
    }

    getHeightByIdForPlayerId(playerId: string, heightId: string): Observable<Height> {
        return this.http.get<Height>(environment.apiUrl + '/v1/players/' + playerId + '/heights/' + heightId);
    }

    getWeightByIdForPlayerId(playerId: string, weightId: string): Observable<Weight> {
        return this.http.get<Weight>(environment.apiUrl + '/v1/players/' + playerId + '/weights/' + weightId);
    }

    addHeight(playerId: string, height: Height): Observable<Height> {
        return this.http.post<Height>(environment.apiUrl + '/v1/players/' + playerId + '/heights', height);
    }

    addWeight(playerId: string, weight: Weight): Observable<Weight> {
        return this.http.post<Weight>(environment.apiUrl + '/v1/players/' + playerId + '/weights', weight);
    }

    deleteHeight(playerId: string, heightId: string) {
        return this.http.delete<any>(environment.apiUrl + '/v1/players/' + playerId + '/heights/' + heightId);
    }

    deleteWeight(playerId: string, weightId: string) {
        return this.http.delete<any>(environment.apiUrl + '/v1/players/' + playerId + '/weights/' + weightId);
    }
}