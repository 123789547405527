import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TeamDetails, TeamSelect } from '../data/team';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ClubDetails } from '../data/club';
import { PlayerList } from '../data/player';

@Injectable({
    providedIn: 'root'
})
export class TeamService {

    constructor(private http: HttpClient) { }

    getClubs(placeId: string, localityId: string) {
        return this.http.get<any>(environment.apiUrl + '/v1/clubs' + '?placeId=' + (placeId ? placeId : '')
            + '&localityId=' + (localityId ? localityId : ''));

    }
    getClubsByPlaceId(placeId: string): Observable<ClubDetails[]> {
        return this.http.get<ClubDetails[]>(environment.apiUrl + '/v1/clubs/place/' + placeId);
    }

    getClubsByAddressId(id: string): Observable<ClubDetails[]> {
        return this.http.get<ClubDetails[]>(environment.apiUrl + '/v1/clubs/address/' + id);
    }


    getTeamSuggests(categoryId: string, clubId: string, genderCode: number): Observable<TeamSelect[]> {
        let club = clubId ? clubId : '';
        return this.http.get<TeamSelect[]>(environment.apiUrl + '/v1/teamsuggests?categoryId=' + categoryId + '&clubId=' + club + '&genderCode=' + genderCode);
    }

    getTeamSelectList(categoryId: string, clubId: string, genderCode: number): Observable<TeamSelect[]> {
        let club = clubId ? clubId : '';
        return this.http.get<TeamSelect[]>(environment.apiUrl + '/v1/teams?categoryId=' + categoryId + '&clubId=' + club + '&genderCode=' + genderCode);
    }

    getTeamDetails(id: string): Observable<TeamDetails> {
        return this.http.get<TeamDetails>(environment.apiUrl + '/v1/teams/' + id);
    }

    addPlayerToTeam(id: string, name: string, firstname: string, lastname: string, nickname: string, teamId: string, positionGroupId: number, currPlayerId: string): Observable<string> {
        const params = {
            teamId,
            positionGroupId,
            name,
            firstname,
            lastname,
            nickname,
            currPlayerId
        };
        return this.http.post<string>(environment.apiUrl + '/v1/teams/' + id + '/mates', params);
    }

    deletePlayerInTeam(teamId: string, playerId: string): Observable<number> {
        return this.http.delete<number>(environment.apiUrl + '/v1/teams/' + teamId + '/players/' + playerId);
    }

    updatePlayerInTeam(id: string, name: string, firstname: string, lastname: string, nickname: string, teamId: string, positionGroupId: number): Observable<number> {
        const params = {
            id,
            teamId,
            positionGroupId,
            name,
            firstname,
            lastname,
            nickname
        };
        return this.http.put<number>(environment.apiUrl + '/v1/teams/' + teamId + '/players/' + id, params);
    }

    getAllPlayersByTeam(teamId: string, clubId: string, catId: string, gender: number): Observable<PlayerList[]> {
        return this.http.get<PlayerList[]>(environment.apiUrl + '/v1/teams/' + teamId + '/players?clubId=' + clubId + '&catId=' + catId + '&gender=' + gender);
    }

    checkTeamName(name: string): Observable<boolean> {
        return this.http.head<boolean>(environment.apiUrl + '/v1/teams?name=' + name);
    }

    deleteTeam(teamId: string): Observable<boolean> {
        return this.http.delete<boolean>(environment.apiUrl + '/v1/teams/' + teamId);
    }
}
