import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PlayerSelect } from '../data/player';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { UserDetails, NotificationModel, PlayerTokenModelList, ErrorModel, ProfileModel } from '../data/user';
import { LanguageModel } from '../data/language';
import { UrlObject } from '../data/form';
import { CoachDetails } from '../data/coach';


@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private http: HttpClient) { }

    getPlayersByUser(): Observable<PlayerSelect[]> {
        return this.http.get<PlayerSelect[]>(environment.apiUrl + '/v1/my-players');
    }


    getCoachByUser(): Observable<CoachDetails> {
        return this.http.get<CoachDetails>(environment.apiUrl + '/v1/my-coach');
    }

    updateUserSlides(): Observable<UserDetails> {
        var updateUserOptions = [{ "path": "SlidesOff", "op": "replace", "value": true }];
        return this.http.patch<UserDetails>(environment.apiUrl + '/v1/my-slides', updateUserOptions);
    }

    getUserDetails(): Observable<UserDetails> {
        return this.http.get<UserDetails>(environment.apiUrl + '/v1/my-users');
    }

    updateUserDetails(user: UserDetails) {
        return this.http.put<UserDetails>(environment.apiUrl + '/v1/my-users', user);
    }

    getLanguagesForUser(): Observable<LanguageModel[]> {
        return this.http.get<LanguageModel[]>(environment.apiUrl + '/v1/my-languages');
    }

    getNotificationsByUser(): Observable<NotificationModel[]> {
        return this.http.get<NotificationModel[]>(environment.apiUrl + '/v1/my-notifications');
    }


    /*** SHARE PLAYER ***/

    generatePlayerTokens(ids: string[], email: string, profile: number): Observable<UrlObject> {
        const params = {
            ids: ids,
            email: email,
            profileType: profile
        };
        return this.http.post<UrlObject>(environment.apiUrl + '/v1/players-tokens', params);
    }

    getPlayerTokenListByToken(token: string): Observable<PlayerTokenModelList> {
        return this.http.get<PlayerTokenModelList>(environment.apiUrl + '/v1/players-tokens?token=' + token);
    }

    acceptInvitation(token: string): Observable<ErrorModel> {
        return this.http.post<ErrorModel>(environment.apiUrl + '/v1/players-users?token=' + token, {});
    }


    /*******************/
}
