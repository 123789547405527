export const environment = {
  appVersion: require('../../package.json').version,
  production: false,
  url: 'https://dev.iqsports.app',
  apiUrl: 'https://dev.api.iqsports.app',
  storageUrl: 'https://iqscoutingdev.blob.core.windows.net',
  storagePills: "pills",
  storageItems: "icons",
  storagePlayers: "players",
  profile: "profile",
  useEmulators: false,
  firebase: {
    apiKey: 'AIzaSyC6sVKaByCJKWVBnFBrMqiZPalSu1ACT0E',
    authDomain: 'iq-scouting-dev.firebaseapp.com',
    databaseURL: 'https://iq-scouting-dev.firebaseio.com',
    projectId: 'iq-scouting-dev',
    storageBucket: 'iq-scouting-dev.appspot.com',
    messagingSenderId: '316322467122',
    appId: '1:316322467122:web:996bbf1b8658b2ac3f9b2b',
    measurementId: 'G-QGY2Y7C5QJ'
  },
  firebaseCollections: {
    quizzes: "quizzes",
    quizzesTemplates: "quizzes-templates",
    pillsTemplates: "pills-templates",
    topics: "topics",
    topicsTemplates: "topicsTemplates",
  },
  sentry: {
    dsn: 'https://13acbbb2c68a42d4941826fc522b036f@o370250.ingest.sentry.io/5179400',
    includeRequestPayload: true,
    sendDefaultPii: true,
    minimumBreadcrumbLevel: 'Error',
    minimumEventLevel: 'Error',
    attachStackTrace: true,
    debug: true,
    diagnosticsLevel: 'Error',
    environment: "develop"
  },
  appInsights: {
    instrumentationKey: 'cd6cd5fc-4d73-48fa-a650-f06d7d6f383c'
  },
  accessCode: 'iqsdemoiqs',
  syncfusionLicense: 'ORg4AjUWIQA/Gnt2VlhhQlJCfV5AQmBIYVp/TGpJfl96cVxMZVVBJAtUQF1hSn9SdkJjWntccHBUQ2Re'
  , Encrypt: {
    Key: "Z1UbeuBT7Uu3SZinrq0vzuDVXBU5FbiK",
    IV: "KUNd9fhw48li2WUZ"
  }
};