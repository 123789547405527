export const AttendanceBy =
{
    COACH: 1,
    PLAYER: 2,
}

export const AttendanceType =
{
    TRAINING: 1,
    GAME: 2,
}


export const AttendanceResult =
{
    YES: true,
    NO: false,
    UNKNOWN: null
}

export const EventDataFilterType =
{
    TODAY: 1,
    YESTERDAY: 2,
    TOMORROW: 3,
    NEXT_WEEK: 4,
    LAST_WEEK: 5,
    NEXT_MONTH: 6,
    LAST_MONTH: 7
}


export const EvenDataFilter = new Map<number, string>([
    [EventDataFilterType.TODAY, 'TODAY'],
    [EventDataFilterType.TOMORROW, 'TOMORROW'],
    [EventDataFilterType.YESTERDAY, 'YESTERDAY'],
    [EventDataFilterType.NEXT_WEEK, 'NEXT_WEEK'],
    [EventDataFilterType.LAST_WEEK, 'LAST_WEEK'],
    [EventDataFilterType.NEXT_MONTH, 'NEXT_MONTH'],
    [EventDataFilterType.LAST_MONTH, 'LAST_MONTH']
]);


export const ReasonNoAttendanceType =
{
    DISEASE: 1,
    HOLIDAYS: 2,
    STUDY: 3,
    INJURY: 4,
    NO_JUSTIFIED: 5,
    OTHERS: 6
}


export const ReasonNoAttendance = new Map<number, string>([
    [ReasonNoAttendanceType.DISEASE, 'DISEASE'],
    [ReasonNoAttendanceType.HOLIDAYS, 'HOLIDAYS'],
    [ReasonNoAttendanceType.STUDY, 'STUDY'],
    [ReasonNoAttendanceType.INJURY, 'INJURY'],
    [ReasonNoAttendanceType.NO_JUSTIFIED, 'NO_JUSTIFIED'],
    [ReasonNoAttendanceType.OTHERS, 'OTHERS']
]);


