import { ProfileValues } from "../const/profile";
import { getLastPlayerStorage } from "./local-storage-functions";

export function getUserProfile(claims): number {
    let profile = null;
    if (claims && claims.profiles) {
        profile = ProfileValues.get(claims.profiles[0].toUpperCase());
    }
    return profile;
}

export function getDefaultPlayerId(claims): string {
    let playerId = null;
    if (claims && claims.players && claims.players.length > 0) {
        const lastPlayerId = getLastPlayerStorage()
        if (lastPlayerId && claims.players.findIndex(p => p == lastPlayerId) >= 0)
            playerId = lastPlayerId;
        else
            playerId = claims.players[0];
    }
    return playerId;
}

export function getUserPlayerIds(claims) {
    let playerIds = [];
    if (claims && claims.players) {
        playerIds = claims.players;
    }
    return playerIds;
}

export function getUserName(claims): string {
    let name = null;
    if (claims && claims.name) {
        name = claims.name;
    }
    return name;
}
export function getUserEmail(claims): string {
    let email = null;
    if (claims && claims.email) {
        email = claims.email;
    }
    return email;
}


export function getCoachId(claims) {
    let coachId = null;
    if (claims && claims.coach) {
        coachId = claims.coach;
    }
    return coachId;
}


