import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { TeamWithPlayers } from '../data/player';
import { GameDetails, TrainingDetails } from '../data/coach';
import { TeamDetails, TeamCreate } from '../data/team';
import { ClubSelect } from '../data/club';

@Injectable()
export class CoachService {

    constructor(private http: HttpClient) { }

    /****TEAM ****/
    createTeam(coachId: string, teamCreate: TeamCreate): Observable<TeamDetails> {
        return this.http.post<TeamDetails>(environment.apiUrl + '/v1/coaches/' + coachId + '/teams', teamCreate);
    }

    getTeamsByCoach(coachId: string): Observable<TeamDetails[]> {
        return this.http.get<TeamDetails[]>(environment.apiUrl + '/v1/coaches/' + coachId + '/teams');
    }

    getClubsByCoach(coachId: string): Observable<ClubSelect[]> {
        return this.http.get<ClubSelect[]>(environment.apiUrl + '/v1/coaches/' + coachId + '/clubs');
    }
    getTeamPlayers(coachId: string, teamId: string): Observable<TeamWithPlayers> {
        return this.http.get<TeamWithPlayers>(environment.apiUrl + '/v1/coach/' + coachId + '/teams/' + teamId + '/players');
    }

    //****TRAINING ****/
    getTrainingsByCoach(coachId: string, fromDate: Date, toDate: Date, globalFilter: string, sortField: string, sortOrder: number, first: number, rows: number): Observable<any> {
        return this.http.get<any>(environment.apiUrl + '/v1/coaches/' + coachId + '/trainings' + '?'
            + (fromDate ? 'fromDate=' + fromDate.toISOString() : '&fromDate=')
            + (toDate ? '&toDate=' + toDate.toISOString() : '&toDate=')
            + '&globalFilter=' + (globalFilter ? globalFilter : '')
            + '&sortField=' + (sortField ? sortField : 'date')
            + '&sortOrder=' + (sortOrder ? sortOrder : 0)
            + '&pageIndex=' + (first / rows + 1)
            + '&pageSize=' + (rows ? rows : 10));
    }
    addTraining(coachId: string, training: TrainingDetails): Observable<any> {
        var teamId = training.team.id;
        return this.http.post<any>(environment.apiUrl + '/v1/coaches/' + coachId + '/teams/' + teamId + '/trainings', training);
    }
    editTraining(training: TrainingDetails): Observable<any> {
        return this.http.put<any>(environment.apiUrl + '/v1/trainings/' + training.id, training);
    }

    getTraining(trainingId: string): Observable<any> {
        return this.http.get<any>(environment.apiUrl + '/v1/trainings/' + trainingId);
    }

    deleteTraining(trainingId: string): Observable<any> {
        return this.http.delete<any>(environment.apiUrl + '/v1/trainings/' + trainingId);
    }

    confirmTrainingAttendance(id: string, type: number, result?: boolean, reasonNoAttendance?: number, comments?: string): Observable<any> {

        var trainingAttendance =
        {
            id: id,
            result: result,
            reasonNoAttendance: reasonNoAttendance,
            comments: comments
        }

        return this.http.put<any>(environment.apiUrl + '/v1/players-trainings/' + id + '?type=' + type, trainingAttendance);
    }

    /****GAMES ****/

    getGamesByCoach(coachId: string, fromDate: Date, toDate: Date, globalFilter: string, sortField: string, sortOrder: number, first: number, rows: number): Observable<any> {
        return this.http.get<any>(environment.apiUrl + '/v1/coaches/' + coachId + '/games' + '?'
            + (fromDate ? 'fromDate=' + fromDate.toISOString() : '&fromDate=')
            + (toDate ? '&toDate=' + toDate.toISOString() : '&toDate=')
            + '&globalFilter=' + (globalFilter ? globalFilter : '')
            + '&sortField=' + (sortField ? sortField : 'date')
            + '&sortOrder=' + (sortOrder ? sortOrder : 0)
            + '&pageIndex=' + (first / rows + 1)
            + '&pageSize=' + (rows ? rows : 10));
    }

    addGame(coachId: string, game: GameDetails): Observable<any> {
        var teamId = game.team.id;
        return this.http.post<any>(environment.apiUrl + '/v1/coaches/' + coachId + '/teams/' + teamId + '/games', game);
    }
    editGame(game: GameDetails): Observable<any> {
        return this.http.put<any>(environment.apiUrl + '/v1/games/' + game.id, game);
    }

    getGame(gameId: string): Observable<any> {
        return this.http.get<any>(environment.apiUrl + '/v1/games/' + gameId);
    }

    deleteGame(gameId: string): Observable<any> {
        return this.http.delete<any>(environment.apiUrl + '/v1/games/' + gameId);
    }

    confirmGameAttendance(id: string, type: number, result?: boolean, reasonNoAttendance?: number, comments?: string): Observable<any> {

        var gameAttendance =
        {
            id: id,
            result: result,
            reasonNoAttendance: reasonNoAttendance,
            comments: comments
        }

        return this.http.put<any>(environment.apiUrl + '/v1/players-games/' + id + '?type=' + type, gameAttendance);
    }

}