export const ProfileType =
{
  PLAYER: 1,
  PARENTS: 2,
  COACH: 4,
  NONE: 0,
}



export const ProfileCodes = new Map<number, string>([
  [ProfileType.PLAYER, 'PLAYER'],
  [ProfileType.PARENTS, 'PARENT'],
  [ProfileType.COACH, 'COACH']
]);

export const ProfileValues = new Map<string, number>([
  ['PLAYER', ProfileType.PLAYER],
  ['PARENT', ProfileType.PARENTS,],
  ['COACH', ProfileType.COACH,]
]);

