import { UserService } from '../../core/services/user.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertController, ModalController, ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { PlayerSelect } from 'src/app/core/data/player';
import { ClipboardService } from 'ngx-clipboard';
import { DeviceDetectorService } from 'ngx-device-detector';
import { take } from 'rxjs';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'share-player',
  templateUrl: 'share-player.modal.component.html',
  styleUrls: []
})
export class SharePlayerModalComponent implements OnInit, OnDestroy {

  @Input() players: PlayerSelect[];
  @Input() userName: string;
  @Input() profile: number;
  selected: PlayerSelect[] = new Array();

  mailSending = false;
  targetEmail: string;
  url: string;

  WHATSAPP = 1;
  CLIPBOARD = 2;

  constructor(private modalController: ModalController,
    private userService: UserService,
    private clipboardService: ClipboardService,
    private deviceService: DeviceDetectorService,
    private translateService: TranslateService,
    private toastController: ToastController,
    private alertController: AlertController) {
  }

  ngOnInit() {
    if (this.players.length == 1) {
      this.selected.push(this.players[0]);
    }
  }

  ngOnDestroy() {
    this.clipboardService.destroy();
  }

  close() {
    this.clipboardService.destroy();
    this.modalController.dismiss();
  }

  checkPlayer(p) {
    const playerIndex = this.selected.findIndex(x => x.id == p.id);
    if (playerIndex >= 0) {
      this.selected = this.selected.splice(playerIndex, 1);
    } else {
      this.selected.push(p);
    }
  }

  share(network) {
    if (this.selected) {
      const ids = this.getSelectedPlayersIds();
      this.userService.generatePlayerTokens(ids, null, this.profile)
        .pipe(take(1)).subscribe({
          next: result => {
            this.url = result.url;
            if (network === this.WHATSAPP) {
              this.shareWhatsapp(this.url);
            }
            else if (network === this.CLIPBOARD) {
              this.modalController.dismiss({
                url: result.url,
              });
              this.ShowToastMessage('FORMS.URL_COPIED', true);
            }
          }, error: err => { if (!environment.production) console.log(err); }
        });
    }
  }


  private shareWhatsapp(url: string) {
    // wa.me
    if (this.deviceService.isMobile()) {
      this.translateService.get(['PROFILE.WHATSAPP_TEXT', 'PROFILE.SOMEBODY']).pipe(take(1)).subscribe(t => {
        let name = this.userName ? this.userName : t['PROFILE.SOMEBODY'];
        let text = ('*' + name + '*') + ' ' + t['PROFILE.WHATSAPP_TEXT'] + ' ⚽' + '\n' + url;
        const whatsappUrl = 'whatsapp://send?text=' + encodeURIComponent(text);
        window.open(whatsappUrl);
      });
    } else {
      this.translateService.get(['PROFILE.WHATSAPP_TEXT', 'PROFILE.SOMEBODY']).pipe(take(1)).subscribe(t => {
        let name = this.userName ? this.userName : t['PROFILE.SOMEBODY'];
        let text = ('*' + name + '*') + ' ' + t['PROFILE.WHATSAPP_TEXT'] + ' ⚽' + '\n' + url;
        const whatsappUrl = 'https://web.whatsapp.com/send?text=' + encodeURIComponent(text);
        // console.log(whatsappUrl);
        window.open(whatsappUrl);
      });
    }
  }

  async sendByEmail() {
    this.translateService.get(['FORMS.EMAIL_TARGET_REQUIRED'
      , 'GENERAL.SEND', 'GENERAL.SHARE_PLAYER_EMAIL_1'
      , 'GENERAL.SHARE_PLAYER_EMAIL_2'])
      .pipe(take(1)).subscribe(async ts => {
        const alert = await this.alertController.create({
          cssClass: 'my-select-class',
          header: '',
          message: this.players.length == 1 ? ts['GENERAL.SHARE_PLAYER_EMAIL_1'] : ts['GENERAL.SHARE_PLAYER_EMAIL_2'],
          inputs: [
            {
              name: "email",
              type: "email",
              placeholder: 'Email',
              value: ""
            }
          ],
          buttons: [
            {
              text: ts['GENERAL.SEND'],
              cssClass: 'slimButton modalTertiaryButton',
              handler: (data) => {
                if (data.email) {
                  this.targetEmail = data.email;
                  this.byEmail(this.targetEmail);
                }
                else {
                  this.ShowToastMessage('FORMS.EMAIL_TARGET_REQUIRED', false);
                }
              }
            }]
        });
        await alert.present();
      });
  }

  private byEmail(email) {
    this.mailSending = true;
    if (!email) {
      this.ShowToastMessage('FORMS.EMAIL_TARGET_REQUIRED', false);
    }
    else {
      const ids = this.getSelectedPlayersIds();
      this.userService.generatePlayerTokens(ids, email, this.profile).pipe(take(1)).subscribe(
        {
          next: r => {
            this.ShowToastMessage('PROFILE.URL_SENT', true);
          }, error: error => {
            if (!environment.production) console.log(error);
            this.ShowToastMessage('ERRORS.ERROR_SENDING_EMAIL', false);
          }
        });
    }
  }

  private getSelectedPlayersIds(): string[] {
    let ids: string[] = [];
    this.selected.forEach(i => {
      ids.push(i.id);
    });

    return ids;
  }

  private ShowToastMessage(messageCode: string, success: boolean) {
    this.translateService.get(messageCode).pipe(take(1)).subscribe(async t => {
      const toast = await this.toastController.create({
        message: t,
        color: success ? 'success' : 'danger',
        duration: 3000
      });
      toast.present();
      this.mailSending = false;
    })
  }

}
