
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { BillingSession, CreateCheckoutSessionResponse } from '../data/plan';

@Injectable({
    providedIn: 'root',
})
export class PaymentService {

    constructor(private http: HttpClient) { }

    createCheckoutSession(priceId: string, q: number): Observable<CreateCheckoutSessionResponse> {
        const params = {
            priceId: priceId,
            quantity: q
        };
        return this.http.post<CreateCheckoutSessionResponse>(environment.apiUrl + '/v1/payments/create-checkout-session', params);
    }

    customerPortal(sessionId: string, customerId: string): Observable<BillingSession> {
        const params = {
            sessionId: sessionId,
            customerId: customerId
        };
        return this.http.post<BillingSession>(environment.apiUrl + '/v1/payments/customer-portal', params);
    }

}
