
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { SubscriptionDetails, SubscriptionDetailsList } from '../data/plan';

@Injectable({
    providedIn: 'root',
})
export class SubscriptionService {

    constructor(private http: HttpClient) { }

    getSubscriptionById(subscriptionId: string): Observable<SubscriptionDetails> {
        return this.http.get<SubscriptionDetails>(environment.apiUrl + '/v1/my-subscriptions/' + subscriptionId);
    }

    getAllActiveSubscriptionsByUser(): Observable<SubscriptionDetailsList> {
        return this.http.get<SubscriptionDetailsList>(environment.apiUrl + '/v1/my-subscriptions');
    }

    changePlan(subscriptionId: string, planId: string): Observable<SubscriptionDetails> {
        const params = {
            subscriptionId: subscriptionId,
            planId: planId
        }
        return this.http.post<SubscriptionDetails>(environment.apiUrl + '/v1/my-subscriptions/' + subscriptionId
            + '/plans', params);
    }
}
