<ion-header>
</ion-header>
<ion-content>
  <ion-refresher slot="fixed" (ionRefresh)="doRefresh($event)">
    <ion-refresher-content>
    </ion-refresher-content>
  </ion-refresher>
  <swiper-container [pagination]="true" [options]="slideOpts" class="slides" #slides navigation="true">
    <swiper-slide>
      <div class="slide-img">
        <img src="/assets/slides/slide-1.png" />
      </div>
      <div class="slide-info">
        <h1>{{'SLIDES.SLIDE_1_TITLE' | translate }}</h1>
        <h2>{{'SLIDES.SLIDE_1_SUBTITLE' | translate }}</h2>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="slide-img">
        <img src="/assets/slides/slide-2.png" />
      </div>
      <div class="slide-info">
        <h1>{{'SLIDES.SLIDE_2_TITLE' | translate }}</h1>
        <h2>{{'SLIDES.SLIDE_2_SUBTITLE' | translate }}</h2>
      </div>
    </swiper-slide>
    <swiper-slide>
      <div class="slide-img">
        <img src="/assets/slides/slide-3.png" />
      </div>
      <div class="slide-info">
        <h1>{{'SLIDES.SLIDE_3_TITLE' | translate }}</h1>
        <h2>{{'SLIDES.SLIDE_3_SUBTITLE' | translate }}</h2>
      </div>
    </swiper-slide>
  </swiper-container>

  <ion-grid class="register">
    <ion-row>
      <ion-col>
        <ion-button (click)="goToLogin()" color="tertiary" shape="round" expand="full"
          class="slimButton">{{'GENERAL.LOGIN' | translate | uppercase }}</ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col>
        <ion-button (click)="goToRegister()" color="secondary" shape="round" expand="full"
          class="slimButton">{{'GENERAL.REGISTER' | translate | uppercase }}</ion-button>
      </ion-col>
    </ion-row>

  </ion-grid>
  <ion-grid class="version">
    <ion-row>
      <p>¿Necesitas ayuda? <a href="https://www.iqscouting.com/ayuda/" target="_blank">Contacta con nosotros</a></p>
    </ion-row>
  </ion-grid>
  <ion-grid class="version">
    <ion-row>
      <p>IQ Scouting - v{{currentApplicationVersion}}</p>
    </ion-row>
  </ion-grid>
  <!--<div class="div-img-text">
    <img src="/assets/axencia-galega-de-innovacion.png" />
  </div>
  <div class="div-img-text">
    <p>Subvencionado pola Axencia Galega de Innovación</p>
  </div>-->
</ion-content>