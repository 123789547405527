import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonInput, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Weight } from '../../../data/biometric';
import { SharedService } from '../../../services/shared.service';
import { SessionDataService } from '../../../session/session-data.service';
import { take } from 'rxjs';
import { BiometricService } from '../../../services/biometric.service';
import { ToastNotifierService } from '../../../services/toast-notifier.service';
import { ScoreAction } from 'src/app/core/const/scoreActions';

@Component({
    selector: 'biometric-weight-modal',
    templateUrl: 'biometric-weight-modal.component.html',
    styleUrls: ['biometric-weight-modal.component.scss']
})
export class BiometricWeightModalComponent implements OnInit {

    // Data passed in by componentProps
    @Input() playerId: string;
    @Input() playerName: string;
    @ViewChild('inputWeight') inputWeight: IonInput;

    weights: Weight[];
    weight: number;
    validate: boolean = true;
    handlerMessage = '';
    saving = false;
    playerNameInfo: string = '';

    constructor(private modalCtrl: ModalController,
        private biometricService: BiometricService,
        private alertCtrl: AlertController,
        private sessionService: SessionDataService,
        private translateService: TranslateService,
        private toastNotifierService: ToastNotifierService,
        private sharedService: SharedService) {
    }

    ngOnInit() {
        if (this.playerName && this.playerName.length > 0) {
            this.playerNameInfo = ": " + this.playerName;
        }

        this.biometricService.getWeightsByPlayerId(this.playerId).pipe(take(1)).subscribe(result => {
            this.weights = result;
        });
    };

    ionViewDidEnter() {
        // Cuando la vista de la modal entra, establece el foco en el input
        setTimeout(() => {
            this.inputWeight.setFocus();
        }, 120); // Ajusta según sea necesario
    }




    addWeight() {
        let weightNew = new Weight();
        weightNew.kilograms = this.weight;
        this.biometricService.addWeight(this.playerId, weightNew).pipe(take(1)).subscribe({
            next: (result) => {
                this.weights.unshift(result);
                this.sessionService.updatePlayerWeight(this.playerId, this.weight);
                this.sharedService.setScoreActions([ScoreAction.AddWeight]);
                this.weight = null;
                this.sharedService.setWeight(true);
            }, error: error => {
                this.toastNotifierService.error(error);
            }
        });
    }



    async deleteWeight(weight) {
        this.translateService.get(['PROFILE.DELETE_WEIGHT', 'PROFILE.QUESTION_DELETE_WEIGHT']).pipe(take(1)).subscribe(async t => {
            const alert = await this.alertCtrl.create({
                header: t['PROFILE.DELETE_WEIGHT'],
                message: t['PROFILE.QUESTION_DELETE_WEIGHT'],
                cssClass: 'my-custom-class',
                buttons: [
                    {
                        text: 'Cancel',
                        cssClass: 'slimButton modalSecondaryButton',
                        role: 'cancel',
                        handler: () => { this.handlerMessage = 'Alert canceled'; }
                    },
                    {
                        text: 'OK',
                        cssClass: 'slimButton modalTertiaryButton',
                        role: 'confirm',
                        handler: () => {
                            this.biometricService.deleteWeight(this.playerId, weight.id).pipe(take(1)).subscribe({
                                next: (result) => {
                                    if (result) {
                                        this.biometricService.getWeightsByPlayerId(this.playerId).pipe(take(1)).subscribe({
                                            next: (result) => {
                                                this.weights = result;
                                                var currentWeight = this.weights && this.weights.length > 0 ? this.weights[0] : null;
                                                this.sessionService.updatePlayerWeight(this.playerId, currentWeight);
                                                this.sharedService.setWeight(true);
                                            }, error: error => {
                                                this.toastNotifierService.error(error);
                                            }
                                        });
                                    }
                                }, error: error => {
                                    this.toastNotifierService.error(error);
                                }
                            });
                            this.handlerMessage = 'Alert confirmed';
                        }
                    }

                ]
            });
            await alert.present();
        });
    }




    closeModal() {
        this.modalCtrl.dismiss({
        });
    }

}


