import { take } from 'rxjs';
import { ToastController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { GetErrorCodes, GetErrorMessage } from '../functions/function';

@Injectable()
export class ToastNotifierService {
    constructor(private toastController: ToastController,
        private translateService: TranslateService) { }

    /**
     * Mostrar un toast con un mensaje de error
     * @param messageCode : código del mensaje
     * @param callback : función a ejecutar después de mostrar el toast
     */
    public failMessage(messageCode: string, callback?: () => void) {
        this.showMessage(messageCode, false, callback);
    }

    /**
    * Mostrar un toast con un mensaje de éxito
    * @param messageCode : código del mensaje
    * @param callback : función a ejecutar después de mostrar el toast
    */
    public successMessage(messageCode: string, callback?: () => void) {
        this.showMessage(messageCode, true, callback);
    }

    /**
     * Mostrar toast con mensaje de error
     * @param error: error devuelto por el api
     * @param defualtMessage : mensaje de error por defecto
     * @param callback : función a ejecutar después de mostrar el toast
     */
    public error(error, defualtMessage?: string, callback?: () => void) {
        var errorCodes = GetErrorCodes(error, defualtMessage);

        //Obtener la traduccion de los mensajes de error
        this.translateService.get(errorCodes).pipe(take(1)).subscribe(async tr => {
            var errorMessage = GetErrorMessage(tr);
            const toast = await this.toastController.create({
                message: errorMessage,
                color: 'danger',
                duration: 2000
            });
            toast.present();
            if (callback)
                callback();
        });
    }

    /**
     * Mostrar toast con mensaje de existo o error
     * @param messageCode : código del mensaje 
     * @param success : indica si es un mensaje de éxito
     * @param callback : funciona a ejeutar después mostrardo el toast
     */
    private showMessage(messageCode: string, success: boolean, callback?: () => void) {
        //Obtener la traduccion del código del mensaje
        this.translateService.get(messageCode).pipe(take(1)).subscribe(async t => {
            const toast = await this.toastController.create({
                message: t,
                color: !success ? 'danger' : 'success',
                duration: 3000
            });
            toast.present();
            if (callback)
                callback();
        });
    }

}



