import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-slides',
  templateUrl: './slides.page.html',
  styleUrls: ['./slides.page.scss'],
})
export class SlidesPage implements OnInit {
  slideOpts = {
    initialSlide: 0,
    speed: 400
  };

  presCode: string;
  previousUrl: string;
  @ViewChild('slides') slides;

  currentApplicationVersion = environment.appVersion;

  constructor(private router: Router,
    private auth: AuthService,
    private route: ActivatedRoute) {

    this.route.params.subscribe(params => {
      this.presCode = params['token'];
    });

  }



  ngOnInit() {

    var ok = localStorage.getItem('demo');

    if (this.presCode) {
      localStorage.setItem('prescriptorCode', this.presCode);
    }
    if (ok != environment.accessCode && document.location.href.indexOf('app.iqscouting') === -1) {
      //this.router.navigate(['/']);
    }
    else {
      if (this.auth.isLoggedIn) {
      }
      else {
        this.router.navigate(['/feed']);
      }
    }
  }

  goToRegister() {
    this.router.navigate(['/auth/register-user']);
  }

  goToLogin() {
    this.router.navigate(['/auth'])
  }


  doRefresh(event) {
    window.location.reload();
  }

}
