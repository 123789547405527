import { FormSelect, FormSetSummaryDetails, UrlObject } from '../data/form';
import { HttpClient, } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PlayerFormData } from '../data/player';
import { environment } from 'src/environments/environment';
import { FormGroupDetailModel } from '../data/formset';
@Injectable({
    providedIn: 'root'
})
export class PlayerFormsService {

    constructor(private http: HttpClient) { }

    getFormSetDetails(id: string): Observable<FormSetSummaryDetails> {
        return this.http.post<FormSetSummaryDetails>(environment.apiUrl + '/v1/players/' + id + '/formsets', {});
    }

    /*getFormSetGroup(id: string, formsetId: string, name: string): Observable<FormGroup> {
        return this.http.get<FormGroup>(environment.apiUrl + '/v1/players/' + id + '/formsets/' + formsetId + '&name=' + name);
    }*/

    getFormGroupDetails(id: string, formsetId: string, type: number): Observable<FormGroupDetailModel> {
        return this.http.get<FormGroupDetailModel>(environment.apiUrl + '/v1/players/' + id + '/formsets/'
            + formsetId + '/forms?type=' + type);
    }

    getDataByToken(token: string): Observable<PlayerFormData[]> {
        return this.http.get<PlayerFormData[]>(environment.apiUrl + '/v1/players?token=' + token);
    }

    generateUrlFormToShare(playerId: string, formId: string): Observable<UrlObject> {
        return this.http.get<UrlObject>(environment.apiUrl + '/v1/players/' + playerId + '/forms/' + formId + '/url-shared');
    }

    generateUrlFormSetToShare(playerId: string, formSetId: string): Observable<UrlObject> {
        return this.http.get<UrlObject>(environment.apiUrl + '/v1/players/' + playerId + '/formsets/' + formSetId + '/url-shared');
    }

    addNewParent(playerId: string, parentName: string): Observable<FormSelect> {
        const params = {
            playerId: playerId,
            parentName: parentName
        };
        return this.http.post<FormSelect>(environment.apiUrl + '/v1/players/' + playerId + '/forms-parents', params);
    }

    sendFormByEmail(playerId: string, formId: string, name: string, email: string): Observable<boolean> {
        const params = {
            playerId: playerId,
            formId: formId,
            name: name,
            email: email
        };
        return this.http.put<boolean>(environment.apiUrl + '/v1/players/' + playerId + '/forms/' + formId, params);
    }

    sendSignalRConnection(connectionId: string, playerId: string): Observable<any> {
        const params = {
            connectionId,
            playerId
        };
        return this.http.post<boolean>(environment.apiUrl + '/v1/players/' + playerId + '/connections', params);
    }




}
