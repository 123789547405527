import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AlertController, IonInput, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Height } from '../../../data/biometric';
import { SharedService } from '../../../services/shared.service';
import { SessionDataService } from '../../../session/session-data.service';
import { take } from 'rxjs';
import { BiometricService } from '../../../services/biometric.service';
import { ToastNotifierService } from '../../../services/toast-notifier.service';
import { ScoreAction } from 'src/app/core/const/scoreActions';

@Component({
    selector: 'biometric-height-modal',
    templateUrl: 'biometric-height-modal.component.html',
    styleUrls: ['biometric-height-modal.component.scss']
})
export class BiometricHeightModalComponent implements OnInit {

    // Data passed in by componentProps
    @Input() playerId: string;
    @Input() playerName: string;
    @ViewChild('inputHeight') inputHeight: IonInput;
    heights: Height[];
    height: number;
    validate: boolean = true;
    handlerMessage = '';
    text: string;
    saving = false;
    playerNameInfo: string = ''


    constructor(private modalCtrl: ModalController,
        private toastNotifierService: ToastNotifierService,
        private biometricService: BiometricService,
        private alertCtrl: AlertController,
        private sessionService: SessionDataService,
        private translateService: TranslateService,
        private sharedService: SharedService
    ) {
    }

    ngOnInit() {
        if (this.playerName && this.playerName.length > 0) {
            this.playerNameInfo = ": " + this.playerName;
        }
        this.biometricService.getHeightsByPlayerId(this.playerId).pipe(take(1)).subscribe({
            next: result => {
                this.heights = result;
            }, error: error => {
                this.toastNotifierService.error(error);
            }
        });

    };
    ionViewDidEnter() {
        // Cuando la vista de la modal entra, establece el foco en el input
        setTimeout(() => {
            this.inputHeight.setFocus();
        }, 120); // Ajusta según sea necesario
    }


    addHeight() {
        let heightNew = new Height();
        heightNew.centimeters = this.height;
        this.biometricService.addHeight(this.playerId, heightNew).pipe(take(1)).subscribe({
            next: (result) => {
                this.heights.unshift(result);
                this.sessionService.updatePlayerHeight(this.playerId, this.height);
                this.height = null;
                this.sharedService.setScoreActions([ScoreAction.AddHeight]);
                this.sharedService.setHeight(true);
            }, error: error => {

                this.toastNotifierService.error(error);
            }
        });
    }


    async deleteHeight(height) {
        this.translateService.get(['PROFILE.DELETE_HEIGHT', 'PROFILE.QUESTION_DELETE_HEIGHT', 'GENERAL.CANCEL'])
            .pipe(take(1)).subscribe(async t => {
                this.text = t;
                const alert = await this.alertCtrl.create({
                    header: t['PROFILE.DELETE_HEIGHT'],
                    message: t['PROFILE.QUESTION_DELETE_HEIGHT'],
                    cssClass: 'my-custom-class',
                    buttons: [
                        {
                            text: t['GENERAL.CANCEL'],
                            cssClass: 'slimButton modalSecondaryButton',
                            role: 'cancel',
                            handler: () => { this.handlerMessage = 'Alert canceled'; }
                        },
                        {
                            text: 'OK',
                            cssClass: 'slimButton modalTertiaryButton',
                            role: 'confirm',
                            handler: () => {
                                this.biometricService.deleteHeight(this.playerId, height.id).pipe(take(1)).subscribe({
                                    next: (result) => {
                                        if (result) {
                                            this.biometricService.getHeightsByPlayerId(this.playerId).pipe(take(1)).subscribe({
                                                next: (result) => {
                                                    this.heights = result;
                                                    var currentHeightCm = this.heights && this.heights.length > 0 ? this.heights[0].centimeters : null;
                                                    this.sessionService.updatePlayerHeight(this.playerId, currentHeightCm);
                                                    this.sharedService.setHeight(true);
                                                }, error: error => {
                                                    this.toastNotifierService.error(error);
                                                }
                                            });
                                        }

                                    }, error: error => {
                                        this.toastNotifierService.error(error);
                                    }
                                });
                                this.handlerMessage = 'Alert confirmed';
                            }
                        }

                    ]
                });
                await alert.present();
            });
    }

    closeModal() {
        this.modalCtrl.dismiss({});
    }



}


