import { PlayerPosition } from 'src/app/core/data/position';
import { LocationModel } from './location';
import { ErrorModel } from './user';
import { PlayerRegister } from './register';


export class PlayerSelect {
    id: string;
    name: string;
    decodeImage: string;
    initials: string;
    hasPlayerProfile: boolean; // Indica si un jugador tiene un perfil de usuario de tipo Jugador.
}
export class PlayerEvolution {
    id: string;
    reportId: string;
    formSetId: string;
    formSetState: number;
}

export class PlayerDetails extends ErrorModel {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    nickName: string;
    initials: string;
    birthdate: string;
    decodeImage: string;
    teamName: string;
    clubName: string;
    catName: string;
    catAge: number;
    teamId: string;
    clubId: string;
    genderCode: string;
    groupPosition: number;
    defaultPosition: PlayerPosition;
    positions: PlayerPosition[];
    country: string;
    lateralityCode: string;
    weight: number;
    height: number;
    hasCoach: boolean;
    prescriptorCode: string;
    managerId: string;
    hasPlayerProfile: boolean; // Indica si un jugador tiene un perfil de usuario de tipo Jugador.

}



// jugador con datos de localizacion
export class EditPlayerDetails {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    nickName: string;
    birthdate: string;
    decodeImage: string;
    defaultPosition: PlayerPosition;
    positions: PlayerPosition[];
    laterality: number;
    code: string;
    height: number;
    weight: number;

    constructor(player: PlayerDetails, laterality: number) {
        this.id = player.id;
        this.name = player.name;
        this.firstName = player.firstName;
        this.lastName = player.lastName;
        this.nickName = player.nickName;
        this.code = player.prescriptorCode;
        this.birthdate = player.birthdate;
        this.decodeImage = player.decodeImage;
        this.defaultPosition = player.defaultPosition;
        this.positions = player.positions;
        this.laterality = laterality;
        this.height = player.height;
        this.weight = player.weight;
    }
}

export class EditPlayerTeamDetails {
    id: string;
    clubAddresses: LocationModel[];
    addressId: string;
    teamName: string;
    clubName: string;
    teamId: string;
    clubId: string;
    genderId: number;
    categoryId: string;
    countryId: number; // País id de la tabla Country
    stateId: number; // Estado id de la tabla State
}

// jugador con datos de localizacion y user
export class CompletePlayerDetails {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    nickName: string;
    birthdate: Date;
    email: string;
    teamName: string;
    clubName: string;
    teamId: string;
    genderId: number;
    clubId: string;
    categoryId: string;
    clubAddresses: LocationModel[];
    profileTypeId: number;
    userName: string;
    userEmail: string;
    defaultPosition: number;
    addressId: string;
    prescriptorCode: string;
    countryId: number; // País id de la tabla Country
    stateId: number; // Estado id de la tabla State

    constructor(playerRegister: PlayerRegister) {
        this.id = playerRegister.id;
        this.countryId = playerRegister.country?.id;
        this.stateId = playerRegister.country?.stateId;
        this.clubId = playerRegister.club?.id;
        this.categoryId = playerRegister.category?.id;
        this.clubName = playerRegister.club.name;
        this.teamName = playerRegister.team.name;
        this.teamId = playerRegister.team.id;
        this.genderId = playerRegister.gender?.id;
        this.profileTypeId = playerRegister.profileId;
        this.userEmail = playerRegister.userEmail;
        this.userName = playerRegister.userName;
        this.name = playerRegister.name;
        this.firstName = playerRegister.firstname;
        this.lastName = playerRegister.lastname;
        this.nickName = playerRegister.nickName;
        this.defaultPosition = playerRegister.position?.id;
        this.clubAddresses = playerRegister.place?.locations;
        this.addressId = playerRegister.addressId;
    }
}


// datos basicos de jugador
export class PlayerNote {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    nickName: string;
    decodeImage: string;
    teamName: string;
    teamId: string;
    clubName: string;
    positionCode: string;
    initials: string;
    isEditable: boolean;
}

// datos basicos de jugador con el modo
export class PlayerFormData {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    decodeImage: string;
    teamName: string;
    teamId: string;
    clubName: string;
    genderCode: string;
    positionCode: string;
    positionGroup: string;
    mode: number;
    initials: string;
    formTitle: string;
    formSubtitle: string;
    numberOfQuestions: number;
    percentage: number;
}


export class PlayerBasic {
    id: string;
    name: string;
    firstName: string;
    lastName: string;
    nickName: string;
    codeImage: string;
    teamName: string;
    clubName: string;
    teamId: string;
    genderId: number;
    genderCode: string;
    clubId: string;
    catId: string;
    catName: string;
    placeId: string; //locality id de la tabla Address
    addressId: string; //address id en la tabla Address
    countryId: number; // País id de la tabla Country
    stateId: number; // Estado id de la tabla State
    countryName: string;
    stateName: string;
    placeName: string
    positionGroupId: number;
}

export class PlayerList {
    id: string;
    name: string;
    surName: string; // concatenacion de ambos apellidos
    firstName: string;
    lastName: string;
    nickName: string;
    birthdate: Date;
    decodeImage: string;
    teamName: string;
    clubName: string;
    defaultPositionName: string;
    groupPosition: number;
    defaultPosition: PlayerPosition;
    positions: PlayerPosition[];
}

export class PlayerPositionDef {
    playerId: string;
    name: string;
    firstName: string;
    lastName: string;
    fullName: string;
    defaultPosition: PlayerPosition;
    hasUserProfile: boolean; // Indica si un jugador tiene un perfil de usuario asociado
    nickName: string;
}

export class TeamWithPlayers {
    teamId: string;
    teamName: string;
    clubId: string;
    clubName: string;
    categoryId: string;
    categoryName: string;
    gender: number;
    players: PlayerPositionDef[]
}

export class ClassificationModel {
    playerId: string;
    numberPosition: number;
    totalScorePlayer: number;
    namePlayer: string;
    surNamePlayer: string;
    playerOutList: boolean;

}