import { Component, OnInit } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../auth.service';
import { validateAllFormFields } from 'src/app/core/functions/function';
import { ToastNotifierService } from 'src/app/core/services/toast-notifier.service';
@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

    loading: boolean;
    userForm: FormGroup;
    disabled: boolean = false;
    constructor(
        public authService: AuthService,
        public app: AppComponent,
        private fb: UntypedFormBuilder,
        private toastNotifierService: ToastNotifierService,
    ) {

        this.userForm = this.fb.group({
            userName: ['', Validators.required]
        });
    }

    ngOnInit() {
    }

    resetPassword() {
        this.disabled = true;
        if (this.userForm.valid) {
            let user = { ...this.userForm.value };
            this.authService.ForgotPassword(user.userName).then((result) => {
                this.disabled = false;
            })
                .catch((error) => {
                    this.disabled = false;
                });
        } else {
            this.disabled = false;
            validateAllFormFields(this.userForm);
            this.toastNotifierService.failMessage("ERRORS.ERROR_FILL_REQUIRED_FIELDS");
        }
    }


}