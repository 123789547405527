import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';
import { getUrlGameStorage } from '../core/functions/local-storage-functions';

@Component({
    selector: 'app-code',
    templateUrl: './code.component.html',
    styleUrls: ['./code.component.scss']
})
export class CodeComponent implements OnInit {

    password: string;

    constructor(
        private auth: AuthService,
        public toastController: ToastController,
        private router: Router) { }

    async presentToast() {
        const toast = await this.toastController.create({
            message: 'access code not valid',
            duration: 2000
        });
        toast.present();
    }

    async ngOnInit() {

        if (this.auth.isLoggedIn) {
            var urlGame = getUrlGameStorage();
            if (urlGame != null) {

                this.router.navigate(['/player/games']);
            } else {
                this.router.navigate(['/feed'])
            }
        } else {
            var ok = localStorage.getItem('demo');

            if (ok == environment.accessCode) {
                this.router.navigate(['/slides']);
            }
        }
    }

    go() {
        if (this.password == environment.accessCode) {
            localStorage.setItem('demo', environment.accessCode);
            this.router.navigate(['/slides']);
        }
        else {
            this.presentToast();
        }
    }
}
