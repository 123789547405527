import { Component, OnInit } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { TranslateService } from '@ngx-translate/core';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';

import { UpdateService } from './core/services/update.service';
import { environment } from 'src/environments/environment';
import { LoggingService } from './core/services/logging.service';

import LogRocket from 'logrocket';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { register } from 'swiper/element/bundle';
declare let gtag: Function;
register();
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {


  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateService,
    private router: Router,
    private updateService: UpdateService,
    private loggingService: LoggingService,
    public afAuth: AngularFireAuth
  ) {

    this.initializeApp();
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        gtag('config', 'G-3DH6Y1VCQ9',
          {
            'page_path': event.urlAfterRedirects
          }
        );
        this.loggingService.logPageView(null, event.urlAfterRedirects);

      }
    });

  }

  ngOnInit(): void {
    if (environment.useEmulators)
      this.afAuth.useEmulator('http://localhost:9099');
    this.router.initialNavigation();

  }

  ngOnDestroy() {

  }

  initializeApp() {

    if (environment.production) {
      LogRocket.init('pswew7/iq-scouting');
    }

    this.updateService.init();
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
    this.translate.setDefaultLang('es-ES');
    this.translate.use('es-ES');
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, environment.storageUrl + '/resources/', '.json');
}
