import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SharedService {
    private subjHeight = new Subject<boolean>();
    private subjWeight = new Subject<boolean>();
    private subjSocre = new Subject<number[]>();
    private subjNotifications = new Subject<boolean>();
    private subjSectionTabBackMap = new Subject<Map<string, { title: string, returnUrl: string, backParams: any }>>();
    private subjSlide = new Subject<Map<string, { slideIndex: number }>>();
    private sectionTabBackMap: Map<string, { title: string, returnUrl: string, backParams: any }> = new Map();
    private slideMap: Map<string, { slideIndex: number }> = new Map();
    constructor() { }

    //Notificaciones
    public updateNotifications() {
        return this.subjNotifications.asObservable();
    }
    public setNotifications(status: boolean) {
        this.subjNotifications.next(status)
    }

    //Puntuación
    public updateScore() {
        return this.subjSocre.asObservable();
    }
    public setScoreActions(actions: number[]) {
        this.subjSocre.next(actions);
    }

    //Altura
    public updateHeight() {
        return this.subjHeight.asObservable();
    }
    public setHeight(updated: boolean) {
        this.subjHeight.next(updated);
    }

    //Peso
    public updateWeight() {
        return this.subjWeight.asObservable();
    }
    public setWeight(updated: boolean) {
        this.subjWeight.next(updated);
    }

    //Botón atrás de la cabecera
    /**
    * Guardar los datos necesarios para congifurar el botón atrás de la cabecera
    * @param pageInstanceId identificador único del la instancia de página
    * @param title título para el botón atrás de la cabecera
    * @param returnUrl url de retono
    * @param backParams datos que a actualizar al cargar "returnUrl"
    */
    public setSectionTabBack(pageInstanceId: string, title: string, returnUrl?: string, backParams?: any): void {
        this.sectionTabBackMap.set(pageInstanceId, { title, returnUrl, backParams: backParams });
        this.subjSectionTabBackMap.next(this.sectionTabBackMap);
    }

    /**
     * Suscripción a los cambios de datos 
     * @returns 
     */
    public updateSectionTabBack() {
        return this.subjSectionTabBackMap.asObservable();
    }

    /**
     * Eliminar los datos del el botón atrás de la cabecera para la instancia de la pagina
     * @param pageInstanceId identificador único del la instancia de página
     */
    public clearSectionBack(pageInstanceId: string): void {
        this.sectionTabBackMap.delete(pageInstanceId);
        this.clearSlide(pageInstanceId);
    }

    /**
     * Actualizar si es necesario los datos compartidos 
     * para la gestión del el botón atrás de la cabecera
     * @param params 
     */
    public updateSectionBack(params: any): void {
        if (params["pageInstanceId"]) {
            if (params["slideIndex"] || params["slideIndex"] == 0) {
                this.setSlide(params["pageInstanceId"], params["slideIndex"]);
            }
        }
    }

    //PillSlide
    /**
    * Suscripción a los cambios de slide
    * @returns 
    */
    public updateSlide() {
        return this.subjSlide.asObservable();
    }

    /**
     * Actualizar los datos compartidos del slide seleccionado para la instancia de página
     * @param pageInstanceId  identificador único del la instancia de página
     * @param slideIndex indice del slide seleccionado
     */
    private setSlide(pageInstanceId: string, slideIndex: number): void {
        this.slideMap.set(pageInstanceId, { slideIndex: slideIndex });
        this.subjSlide.next(this.slideMap);
    }

    /**
     * Eliminar los datos del slide seleccionado para la instancia de la página
     * @param pageInstanceId identificador único del la instancia de página
     */
    private clearSlide(pageInstanceId: string): void {
        if (this.slideMap?.has(pageInstanceId))
            this.slideMap.delete(pageInstanceId);
    }


}
