
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { PlanDetails } from '../data/plan';

@Injectable({
    providedIn: 'root',
})
export class PlanService {

    constructor(private http: HttpClient) { }

    getPlans(): Observable<PlanDetails[]> {
        return this.http.get<PlanDetails[]>(environment.apiUrl + '/v1/plans');
    }

}
