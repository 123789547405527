<ion-header traslucent>
    <ion-toolbar class="alignCenter">
        <ion-label>{{ "SCORE.NEW_POINTS" | translate }}</ion-label>
        <ion-buttons slot="end">
            <ion-icon name="close-outline" (click)="close()"></ion-icon>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-grid *ngIf="score.lastActions">
        <ion-row *ngFor="let action of score.lastActions">
            <ion-col size="12">
                <ion-item>
                    <i class="material-symbols-outlined" slot="start">
                        star_rate
                    </i>
                    <ion-label>{{ ("SCORE."+ action.actionCode) | translate }}</ion-label>
                    <ion-label class="score" slot="end">{{action.score}}</ion-label>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row class="ion-margin-top">
            <ion-col size="12">
                <ion-item lines="none" class="custom-item">
                    <ion-label class="ion-text-center">
                        <i class="material-symbols-outlined">emoji_events</i>
                        {{ "SCORE.TOTAL_POINTS"| translate }}
                        <span class="total-score" slot="end">{{score.totalScore}}</span>
                    </ion-label>
                </ion-item>
            </ion-col>
        </ion-row>
        <ion-row>
            <img alt="Mascota" src="/assets/mascota-01.png" />
        </ion-row>
    </ion-grid>
</ion-content>
<ion-footer>
    <ion-grid>
        <ion-row>
            <ion-col>
                <ion-button color="tertiary" shape="round" expand="full" (click)="close()" class="slimButton">
                    {{'GENERAL.CLOSE' | translate}}
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-grid>
</ion-footer>