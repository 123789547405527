import { Injectable, ApplicationRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { Platform, AlertController, ToastController } from '@ionic/angular';
import { first } from 'rxjs/operators';

/**
 * This services handles automatic installation and updates for PWAs
 */
@Injectable({
  providedIn: 'root'
})
export class UpdateService {
  private promptInstallEvent;
  private toast: HTMLIonToastElement;

  constructor(
    private updates: SwUpdate,
    private appRef: ApplicationRef,
    private toastCtrl: ToastController,
    private alertCtrl: AlertController,
    private platform: Platform) {

    this.appRef.isStable
      .pipe(first(stable => stable === true))
      .subscribe(() => this.init());
  }

  get isMobile() {
    return this.platform.is('mobile');
  }

  get isPWAInstalled(): boolean {
    return window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone;
  }

  async init() {
    // console.log(`Runing app ${this.isPWAInstalled ? 'standalone' : 'in browser'}`);

    this.updates.available.subscribe(async event => {

      /* console.log('current version is', event.current);
      console.log('available version is', event.available); */

      if (event.current !== event.available) {
        const alert = await this.alertCtrl.create({
          header: 'Actualización Disponible',
          subHeader: 'Hay una nueva versión de la aplicación disponible',
          message: '¿Deseas descargarla ahora?',
          buttons: [
            {
              text: 'Actualizar',
              handler: () => { this.updates.activateUpdate(); }
            },
            'Después'
          ]
        });
        alert.present();
      }
    });

    this.updates.activated.subscribe(event => {
      /*  console.log('old version was', event.previous);
       console.log('new version is', event.current); */
      window.location.reload();
    });

    await this.platform.ready();

    // console.log('isMobile', this.isMobile);

    /*  if (environment.production) {
       this.checkForUpdate();
       if (!this.isPWAInstalled) this.listenForInstallEvent();
     } */

  }

  async checkForUpdate() {
    //console.log('Check for updates');
    await this.updates.checkForUpdate();
  }

  private listenForInstallEvent() {
    if (localStorage.getItem("installed") != "true") {
      window.addEventListener('beforeinstallprompt', async (e) => {
        e.preventDefault();
        this.promptInstallEvent = e;
        setTimeout(() => {
          this.suggestInstall();
        }, 3e3);
      });
    }
  }

  private async suggestInstall() {
    this.toast = await this.toastCtrl.create({
      message: 'Puedes añadir IQS a la pantalla de inicio',
      buttons: [{
        text: 'Añadir',
        handler: () => {
          localStorage.setItem("installed", "true");
          this.installPWA();
        },
      }, {
        text: '',
        icon: 'close'
      }],
      duration: 0,
    });
    this.toast.present();
  }

  private installPWA() {
    this.toast.dismiss();
    // Show the prompt
    this.promptInstallEvent.prompt();
    // Wait for the user to respond to the prompt
    this.promptInstallEvent.userChoice
      .then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          //console.log('User accepted the A2HS prompt');
        } else {
          //console.log('User dismissed the A2HS prompt');
        }
        this.promptInstallEvent = null;
      });
  }
}