<ion-header>
</ion-header>
<ion-content [scrollY]="true">
    <form [formGroup]="userForm">
        <ion-grid class="user-form custom-form">
            <ion-row>
                <ion-col><img class="logo"
                        src="https://www.iqscouting.com/wp-content/uploads/2020/11/logo-iqs-white-slogan.svg"></ion-col>
            </ion-row>
            <ion-row class="title">
                <ion-col> <ion-label> {{'AUTH.SIGN_IN_TITLE' | translate}}</ion-label></ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <ion-item>
                        <ion-input class="email" type="email" formControlName="userName" required
                            placeholder="{{'AUTH.EMAIL' | translate }}" autocomplete="off">
                        </ion-input>
                    </ion-item>
                </ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <ion-item>
                        <ion-input class="password" formControlName="userPassword" type="password" required
                            placeholder="{{'AUTH.PASSWORD' | translate }}" autocomplete="off">
                        </ion-input>
                    </ion-item>
                </ion-col>
            </ion-row>
            <ion-row class="marginTop20 lost-password">
                <ion-col> <a routerLink="/auth/forgot-password"> {{'AUTH.FORGOT_PASSWORD' | translate}}</a></ion-col>
            </ion-row>
            <ion-row>
                <ion-col>
                    <ion-button [disabled]="disabled" (click)="signIn()" color="tertiary" shape="round" expand="full"
                        class="slimButton">{{'AUTH.SIGN_IN' | translate | uppercase }}</ion-button>
                </ion-col>
            </ion-row>
            <ion-row class="marginTop20 info-sing-in-up">
                <ion-col>{{'AUTH.NO_ACCOUNT' | translate | uppercase }} <a routerLink="/auth/register-user">
                        {{'AUTH.SIGN_UP' | translate| uppercase}}</a></ion-col>
            </ion-row>
        </ion-grid>
    </form>
</ion-content>